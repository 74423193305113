import DataTableOld, {ColumnOld} from "Core/Table/data-table-old";
import React, {SetStateAction, useState} from "react";
import DataTableTools, {Option} from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {
    AttendancesPayload,
    AttendancesRequest,
    attendancesUrl,
} from "AttendanceManagement/Controllers/attendances-controller";
import {AttendanceFormatted} from "AttendanceManagement/Controllers/attendances-controller";
import moment from "moment";
import {useIntl, FormattedMessage} from "react-intl";
import {RadioGroup, RadioGroupItem} from "components/radio-group";
import {Label} from "components/label";

export const statuses: Option[] = [
  {
    value: "Check In",
    label: "Check In",
    translationName: 'check.in'
  },
  {
    value: "Check Out",
    label: "Check Out",
    translationName: 'check.out'
  },
  {
    value: "Fehlend",
    label: "Missing",
    translationName: 'missing'
  },
  {
    value: "Feiertag",
    label: "Public Holiday",
    translationName: 'public.holiday'
  },
  {
    value: "Krankheit",
    label: "Sick Leave",
    translationName: 'sick.leave'
  },
  {
    value: "Freizeitausgleich",
    label: "Compensatory Time Off",
    translationName: 'compensatory.time.off'
  },
  {
    value: "Urlaub",
    label: "Vacation",
    translationName: 'vacation'
  }
]

type AttendanceDataTableProps = {
    request: AttendancesRequest
    formattedData: AttendanceFormatted[]
    detailData: AttendanceFormatted | null
    setDetailData: React.Dispatch<AttendanceFormatted | null>
    payload: AttendancesPayload
    setPayload: React.Dispatch<SetStateAction<AttendancesPayload>>
    loading: boolean
}

const AttendancesDataTable = (
    {
        request,
        formattedData,
        detailData,
        setDetailData,
        payload,
        setPayload,
        loading
}: AttendanceDataTableProps) => {
    const intl = useIntl();
    const [dateFilterChoice, setDateFilterChoice] = useState<"single" | "range">("single");

    const attendanceColumns: ColumnOld[] = [
        {
            backendKey: "userFullName",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.employee", defaultMessage: "Employee"}),
            sortable: true
        },
        {
            backendKey: "checkInDate",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.date", defaultMessage: "Date"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "checkInTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.checkIn", defaultMessage: "Check In"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "checkOutTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.checkOut", defaultMessage: "Check Out"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "totalDuration",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.duration", defaultMessage: "Duration"}),
            sortingValue: "duration",
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.status", defaultMessage: "Status"}),
            sortable: true
        }
    ]

    const handleDateFilterChange = (value: SetStateAction<"single" | "range">) => {
        setDateFilterChoice(value);
        if (typeof value === "string") {
            if (value === "single") {
                // When switching to single date, remove both date filters
                setPayload(prev => {
                    const { ["filter.checkOutDate"]: _, ["filter.checkInDate"]: __, ...rest } = prev;
                    return rest;
                });
            } else {
                // When switching to range, remove any existing date filter
                setPayload(prev => {
                    const { ["filter.checkInDate"]: _, ...rest } = prev;
                    return rest;
                });
            }
        }
    };

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <DataTableTools
                        payload={payload}
                        setPayload={setPayload}
                        filters={[
                            {
                                type: "simple",
                                title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                                filterBy: 'filter.status',
                                options: statuses ?? [],
                            },
                            dateFilterChoice === "single" ? {
                                type: "date",
                                date: "filter.checkInDate",
                                disabled: new Date()
                            } : {
                                type: "dateFromTo",
                                from: "filter.checkInDate",
                                to: "filter.checkOutDate",
                                disabled: new Date()
                            },
                            
                            // {
                            //     type: "switch",
                            //     label: "daily tracker",
                            //     value: isDailyTrack,
                            //     dispatch: setDailyTrack
                            // }
                        ]}
                        dateFilterChoice={dateFilterChoice}
                        setDateFilterChoice={handleDateFilterChange}
                        exportTable={{
                            apiURL: attendancesUrl + "export/",
                            fileName: `${moment().valueOf()}_attendance`,
                            payload: payload
                        }}
                    />
                </div>
                {/* {isDailyTrack ? (
                    <DataTableOld
                        data={formattedData}
                        columns={[]}
                        loading={loading}
                        detailData={detailData}
                        setPayload={setPayload}
                        setDetailData={setDetailData}
                        payload={payload}
                        dailyTrack={
                            <Calendar
                                employees={formattedData}
                                date={payload["filter.checkInDate"]}
                                setDetailData={setDetailData}
                            />
                        }
                    />
                ) : ( */}
                    <DataTableOld
                        data={formattedData}
                        loading={loading}
                        setDetailData={setDetailData}
                        detailData={detailData}
                        setPayload={setPayload}
                        columns={attendanceColumns}
                        payload={payload}
                        showLoadingState={true}
                    />
                {/* )} */}
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default AttendancesDataTable;