import * as React from "react"
import {SetStateAction, useEffect} from "react";
import {DatePickerWithRange} from "../../components/date-picker-with-range";
import {DateRange} from "react-day-picker";
import formatDate from "../functions/format-date";
import {Button} from "../../components/button";
import {Cross2Icon} from "@radix-ui/react-icons";
import {DateFilter, DateFromToFilter} from "./data-table-tools";
import {DatePicker} from "../../components/date-picker";
import {FormattedMessage} from "react-intl";
import {DataTablePayload} from "./interfaces";
import moment from "moment";


interface DataTableDateFilterProps {
    filter: DateFilter | DateFromToFilter
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
}

export function DataTableDateFilter({
    filter,
    payload,
    setPayload
}: DataTableDateFilterProps) {
    const [dateRange, setDateRange] = React.useState<DateRange | undefined>();
    const [date, setDate] = React.useState<Date | undefined>();

    // Only set default for single date picker
    useEffect(() => {
        if (filter.type === "date") {
            setDate(new Date())
        }
    }, []); // Empty dependency array means this only runs once on mount

    // Handle date range changes
    useEffect(() => {
        if (setPayload && filter.type === "dateFromTo") {
            if (dateRange) {
                setDate(undefined); // Clear single date when date range is set
                setPayload((prevState: any) => ({
                    ...prevState,
                    start: 0,
                    [filter.from]: dateRange.from ? formatDate(dateRange.from) : "",
                    [filter.to]: dateRange.to ? formatDate(dateRange.to) : "",
                }))
            } else {
                // When dateRange is undefined (after reset), clear the filter values
                setPayload((prevState: any) => ({
                    ...prevState,
                    start: 0,
                    [filter.from]: "",
                    [filter.to]: ""
                }))
            }
        }
    }, [dateRange]);

    // Handle single date changes
    useEffect(() => {
        if (setPayload && filter.type === "date" && date) {
            setDateRange(undefined); // Clear date range when single date is set
            setPayload((prevState: any) => ({
                ...prevState,
                start: 0,
                [filter.date]: formatDate(date)
            }))
        }
    }, [date]);

    useEffect(() => {
        const payloadValue = payload[filter.type === "date" ? filter.date : filter.from]
        if (payloadValue) {
            if (filter.type === "date") {
                setDate(new Date(payloadValue))
            } else if (filter.type === "dateFromTo") {
                const payloadValueTo = payload[filter.to]
                if (payloadValueTo) {
                    setDateRange({
                        from: new Date(payloadValue),
                        to: new Date(payloadValueTo)
                    })
                }
            }
        }
    }, []);

    return (
        <>
            {(filter && filter.type === "dateFromTo") ? (
                <>
                    <DatePickerWithRange 
                        date={dateRange} 
                        setDate={setDateRange} 
                        disabled={filter.disabled}
                        defaultFrom={filter.defaultFrom}
                        defaultTo={filter.defaultTo}
                    />
                    {dateRange && (
                        <Button
                            variant="ghost"
                            onClick={() => {
                                setDateRange(undefined);
                                // Clear the filter values when resetting
                                setPayload((prevState: any) => ({
                                    ...prevState,
                                    start: 0,
                                    [filter.from]: "",
                                    [filter.to]: ""
                                }));
                            }}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    )}
                </>
            ) : (
                <>
                    <DatePicker date={date} setDate={setDate} disabled={filter.disabled}/>
                    {(date && filter?.defaultValue && formatDate(date) !== formatDate(filter?.defaultValue as Date)) ? (
                        <Button
                            variant="ghost"
                            onClick={() => setDate(filter?.defaultValue)}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    ) : (date && !filter?.defaultValue) ? (
                        <Button
                            variant="ghost"
                            onClick={() => {
                                setDate(undefined);
                                // Clear the filter value when resetting
                                setPayload((prevState: any) => ({
                                    ...prevState,
                                    start: 0,
                                    [filter.date]: ""
                                }));
                            }}
                            className="h-8 text-xs px-2 lg:px-3"
                        >
                            <FormattedMessage id={"reset"} defaultMessage={"Reset"}/>
                            <Cross2Icon className="ml-2 h-4 w-4" />
                        </Button>
                    ) : null}
                </>
            )}
        </>
    )
}


