import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../../components/button"
import {
    Form, FormControl, FormField, FormItem, FormLabel,
    FormMessage,
} from "../../../components/form"
import FormTitle from "../../../Core/components/form-title";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {DatePicker} from "../../../components/date-picker";
import {Switch} from "../../../components/switch";
import {TableCell, TableRow} from "../../../components/table";
import {MagicWand} from "@phosphor-icons/react";
import {TimeValue} from "react-aria";
import {TimePicker} from "../../../components/date-time-picker/time-picker";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {employeesUrl, EmploymentInfo} from "../../Controllers/employee-controller";
import {toast} from "../../../components/use-toast";
import formatDate from "../../../Core/functions/format-date";
import {employeeManagementUrl} from "../../index";
import {employmentType} from "../../Controllers/employment-type-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../../Core/functions/use-server-errors";
import ErrorMessageList from "../../../Core/components/ErrorMessageList";
import SearchInput from "../../../Core/components/search-input";
import moment from "moment";
import {cn} from "../../../lib/utils";
import {nullOrUndefined} from "../../../Core/constants/variables";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {useWorkingPlan} from "../../../Core/WorkingPlan/use-working-plan";
import {WorkingPlanBody} from "../../../Core/WorkingPlan/components";
import {TWorkingPlanBreak} from "../../../Core/WorkingPlan/interfaces";

function formatTimeValue(time?: string | null | TimeValue | { hours: number; minutes: number }, method?: "toString" | "toObject") {
    if (time === undefined || time === null) return;
    switch (method) {
        case "toString":
            if (typeof time !== "object") {
                throw new Error("Invalid input: 'toObject' method expects an object, not a string.");
            } else if ("hours" in time && "minutes" in time) {
                return [time.hours, time.minutes].join(":");
            }
            const updatedHour = (time.hour.toString()?.length === 1) ? `0${time.hour}` : time.hour;
            const updatedMinute = (time.minute.toString()?.length === 1) ? `0${time.minute}` : time.minute;
            return `${updatedHour}:${updatedMinute}`

        case "toObject":
            if (typeof time !== "string") {
                throw new Error("Invalid input: 'toString' method expects a string, not an object.");
            }
            const updatedTime = time.split(':').map((value) =>
                (value?.length === 1) ? Number(`0${value}`) : Number(value)
            );
            const [hour, minute] = updatedTime;
            return {hour, minute};

        default:
            return;

    }
}

const formSchema = z.object({
    date: z.date(),
    employmentTypeID: z.string().optional(),
    records: z.any(),
});

type FormAddEmploymentProps = {
    userUUID: string
    reFetch: Dispatch<SetStateAction<boolean>>
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    reFetchWorkingPlans: Dispatch<SetStateAction<boolean>>
    rePopulateRecord: (uuid: string) => void
}

type WorkingPlanSchemaType = {
    pk?: string
    day: number
    dayName: string
    startTime: TimeValue | null | undefined
    endTime: TimeValue | null | undefined
    checked: boolean
    breaktimes: TWorkingPlanBreak[]
    breaktimeHelper: TWorkingPlanBreak
}

type WorkingPlanDurationSchemaType = {
    day: number
    duration: moment.Duration
    formattedDuration: string
}

const WorkingPlanSchema: WorkingPlanSchemaType[] = [
    {
        day: 1,
        dayName: "Monday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 2,
        dayName: "Tuesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 3,
        dayName: "Wednesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 4,
        dayName: "Thursday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 5,
        dayName: "Friday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 6,
        dayName: "Saturday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 7,
        dayName: "Sunday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    }
]

const WorkingPlanDurations: WorkingPlanDurationSchemaType[] = [
    {
        day: 1,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 2,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 3,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 4,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 5,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 6,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 7,
        duration: moment.duration(0),
        formattedDuration: "0"
    }
]

export const FormAddEmploymentType: React.FC<FormAddEmploymentProps>  = ({
    userUUID,
    reFetch,
    reLoadWorkingTimeProgress,
    rePopulateRecord,
    reFetchWorkingPlans
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            records: (() => {
                return WorkingPlanSchema.map((day) => {
                    return {
                        pk: null,
                        day: day.day,
                        startTime: null,
                        endTime: null,
                        checked: false
                    }
                })
            })()
        },
    });
    const [isValidated, setIsValid] = useState<boolean>(false);
    const [workingPlan, setWorkingPlan] = useState<WorkingPlanSchemaType[]>(WorkingPlanSchema);
    const [workingPlanDurations, setWorkingPlanDurations] = useState<WorkingPlanDurationSchemaType[]>(WorkingPlanDurations);
    const [totalDurations, setTotalDurations] = useState<moment.Duration | null>(moment.duration(0));
    const [employmentTypes, setEmploymentTypes] = useState<employmentType[]>();
    const [employmentInfo, setEmploymentInfo] = useState<employmentType>();
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const {createWithAI, handleSwitchChange} = useWorkingPlan(setWorkingPlan);

    const validateEmploymentInfos = (employmentInfo: EmploymentInfo) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(employeesUrl + `${userUUID}/employment-infos/validate/`, employmentInfo)
                .then((res) => {
                    resolve({
                        isValid: true
                    });
                })
                .catch((err) => {
                    reject({
                        isValid: false,
                        errors: err.response.data
                    });
                });
        });
    }
    
    function handleValidation(values: z.infer<typeof formSchema>) {
        const data = {
            ...values,
            date: formatDate(values.date)
        }

        if (data.employmentTypeID === "no-value") {
            return form.setError("employmentTypeID", {message: "Please select an employment type"});
        } else {
            form.clearErrors("employmentTypeID");
            setEmploymentInfo(employmentTypes?.find(elem => elem.pk === data.employmentTypeID))
        }

        validateEmploymentInfos(data as any)
            .then((res: any) => {
                setIsValid(res.isValid)
                setApiErrors({});
            })
            .catch((err: any) => {
                setIsValid(err.isValid);
                setApiErrors(err.errors);
            })
    }


    const onSubmit = (values: z.infer<typeof formSchema>) => {
        setApiErrors({});

        const data = {
            date: formatDate(values.date),
            employmentTypeID: values.employmentTypeID,
            workingPlan: {
                records: (() => {
                    const filteredRecords = workingPlan.filter((record: any) => record.checked)
                    return filteredRecords?.map((day: any) => {
                        return {
                            pk: null,
                            day: day.day,
                            startTime: formatTimeValue(day?.startTime as TimeValue, "toString"),
                            endTime: formatTimeValue(day?.endTime as TimeValue, "toString")
                        }
                    })
                })()
            }
        }
        axiosInstance.post(employeesUrl + `${userUUID}/employment-infos/`, data)
            .then((res) => {
                setApiErrors({});
                setWorkingPlan(WorkingPlanSchema);
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
                reLoadWorkingTimeProgress(true);
                reFetchWorkingPlans(true);
                rePopulateRecord(userUUID);
            })
            .catch((err) => {
                if (err.response.data.hasOwnProperty("workingPlan")) {
                    // TODO catch non_field_errors
                    if (err.response.data.workingPlan?.non_field_errors)
                        setApiErrors(err.response.data.workingPlan)

                    else{
                        const recordsErrors = err.response.data.workingPlan?.records
                        Object.keys(recordsErrors).forEach((key) => {
                            const selectedDays = workingPlan.filter((_: any) => _.checked);
                            const iterations = selectedDays?.length === recordsErrors.length ? recordsErrors.length : 0;
                            if (!selectedDays) return;
                            for (let i = 0; i <= iterations; i++) {
                                const currentDay = selectedDays[i]?.day;
                                if (currentDay && "startTime" in recordsErrors[i]) {
                                    form.setError(`records.${currentDay - 1}.startTime`, {message: "Field is empty"})
                                }
                                if (currentDay && "endTime" in recordsErrors[i]) {
                                    form.setError(`records.${currentDay - 1}.endTime`, {message: "Field is empty"})
                                }
                            }
                        })
                }
                }
            })
    };

    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchEmployeeTypes();
    }, []);

    useEffect(() => {
        setWorkingPlanDurations((prevState) => {
            return prevState.map((elem) => {
                let currentDay = workingPlan.find(day => elem.day === day.day)

                if (!currentDay) return elem;

                let startTime = currentDay ? moment().set({
                    hours: currentDay.startTime?.hour,
                    minutes: currentDay.startTime?.minute,
                }) : null;
                let endTime = currentDay ? moment().set({
                    hours: currentDay.endTime?.hour,
                    minutes: currentDay.endTime?.minute,
                }) : null;
                let hours = "00h";
                let minutes = "00m";
                let formattedDuration = `${hours} ${minutes}`;
                let duration: moment.Duration = moment.duration(0);

                let isReady = !!(
                    String(currentDay.startTime?.hour ?? "").length &&
                    String(currentDay.startTime?.minute ?? "").length &&
                    String(currentDay.endTime?.hour ?? "").length &&
                    String(currentDay.endTime?.minute ?? "").length
                );

                if (
                    isReady &&
                    startTime &&
                    endTime
                ) {

                    if (startTime.isAfter(endTime)) {
                        endTime.add(1, "d")
                    }

                    duration = endTime ? moment.duration(endTime.diff(startTime)) : moment.duration(0);
                    if (duration) {
                        hours = Math.floor(duration.asHours()).toString();
                        minutes = duration.minutes().toString();

                        formattedDuration = (() => {
                            let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                            let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                            return `${formattedHours} ${formattedMinutes}`
                        })();
                    }
                }

                return {...elem, formattedDuration, duration};
            })
        })
    }, [workingPlan]);

    useEffect(() => {
        const totalDurationArray = workingPlanDurations.map(elem => elem.duration)
        const totalDurationsCalculated = totalDurationArray.reduce((accumulator, currentValue) => {
            return accumulator?.add(currentValue)
        })
        setTotalDurations(totalDurationsCalculated)
    }, [workingPlanDurations]);

    return (
    <Form {...form}>
        <form onSubmit={(e) => {
            e.preventDefault();
            onSubmit(form.getValues());
        }} className="flex flex-col gap-4">
            {!isValidated ? (
                <div className="flex flex-col gap-4">
                    <FormField
                        control={form.control}
                        name="employmentTypeID"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.employmentHistory.type"}
                                        defaultMessage={"Type"}
                                    />
                                </FormLabel>
                                <SearchInput
                                    title={intl.formatMessage({id: "employees.forms.employmentHistory.pickType", defaultMessage: "Pick a type"})}
                                    values={employmentTypes?.map((employmentType) => (
                                        {key: employmentType.type, value: employmentType.pk}
                                    )) ?? []}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="date"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.employmentHistory.date"}
                                        defaultMessage={"Date"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <DatePicker date={field.value} setDate={field.onChange}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <ErrorMessageList errors={errors.detailErrors}/>
                    <ErrorMessageList errors={errors.nonFieldErrors}/>

                    <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                        <DialogClose ref={closeModal as any} asChild>
                            <Button className="hidden">close</Button>
                        </DialogClose>
                        <DialogClose asChild>
                            <Button variant="outline"><FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/></Button>
                        </DialogClose>
                        <Button
                            variant="taimDefault"
                            onClick={(e) => {
                                e.preventDefault();
                                handleValidation(form.getValues());
                            }}
                            type="button"
                        ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                    </DialogFooter>
                </div>
            ) : (
                <div className="flex flex-col gap-4 overflow-auto no-scrollbar">
                    <DetailViewCard rows={[
                        {
                            name: intl.formatMessage({
                                id: "employees.forms.addWorkingPlan.typeOfEmployment",
                                defaultMessage: "Type Of Employment"
                            }),
                            value: employmentInfo?.type ?? nullOrUndefined
                        },
                        {
                            name: intl.formatMessage({
                                id: "employees.forms.addWorkingPlan.hoursPerMonth",
                                defaultMessage: "Hours Per Month"
                            }),
                            value: employmentInfo?.monthlyWorkHours ?? nullOrUndefined
                        },
                        {
                            name: intl.formatMessage({
                                id: "employees.forms.addWorkingPlan.hoursPerWeek",
                                defaultMessage: "Hours Per Week"
                            }),
                            value: employmentInfo?.monthlyWorkHours ? Number(employmentInfo.monthlyWorkHours) / 4 : nullOrUndefined
                        },
                    ]}/>
                    <div className="flex flex-row justify-between items-center">
                        <div>
                            <FormTitle title={intl.formatMessage({id: "employmentType.workingPlan", defaultMessage: "Working Plan"})}/>
                            <span className="text-slate-500 text-xs">Configure at first the Time Plots for the working days</span>
                        </div>
                        <Button
                            type="button"
                            variant={"taimDefault2"}
                            className="flex flex-row gap-2"
                            onClick={createWithAI}
                        >
                            <span>Create with AI</span>
                            <MagicWand size={16} color="#233B99"/>
                        </Button>
                    </div>
                    <WorkingPlanBody>
                        {workingPlan ? (
                            workingPlan.map((day, index) => (
                                <TableRow className="border-none">
                                    <TableCell>
                                        <FormattedMessage
                                            id={day.dayName.toLowerCase()}
                                            defaultMessage={day.dayName}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormField
                                            control={form.control}
                                            name={`records.${index}.startTime`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <TimePicker
                                                            label={field.name}
                                                            isDisabled={!day?.checked}
                                                            value={day.startTime}
                                                            onChange={(value) => {
                                                                if (!value) return;
                                                                setWorkingPlan(prevState => (
                                                                    prevState.map(elem => {
                                                                        if (elem.day === day.day) {
                                                                            return {
                                                                                ...elem,
                                                                                startTime: value
                                                                            }
                                                                        }
                                                                        return  elem;
                                                                    })
                                                                ))
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormField
                                            control={form.control}
                                            name={`records.${index}.endTime`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <TimePicker
                                                            label={field.name}
                                                            isDisabled={!day?.checked}
                                                            value={day.endTime}
                                                            onChange={(value) => {
                                                                if (!value) return;
                                                                setWorkingPlan(prevState => (
                                                                    prevState.map(elem => {
                                                                        if (elem.day === day.day) {
                                                                            return {
                                                                                ...elem,
                                                                                endTime: value
                                                                            }
                                                                        }
                                                                        return  elem;
                                                                    })
                                                                ))
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>{workingPlanDurations.find(elem => elem.day === day.day)?.formattedDuration}</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={day.checked}
                                            className="data-[state=checked]:bg-blue-900"
                                            onClick={() => {
                                                handleSwitchChange(index);
                                                setWorkingPlan(prevState => (
                                                    prevState.map(elem => {
                                                        if (elem.day === day.day) {
                                                            return {
                                                                ...elem,
                                                                startTime: null,
                                                                endTime: null,
                                                                checked: !day.checked
                                                            }
                                                        }
                                                        return elem;
                                                    })
                                                ))
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : null}
                        <TableRow>
                            <TableCell colSpan={3} className={"text-right"}>
                                <span className="">Total</span>
                            </TableCell>
                            <TableCell colSpan={2}>{(() => {
                                let hours = "00h";
                                let minutes = "00m";
                                let formattedDuration = `${hours} ${minutes}`;
                                let isSuffisent: boolean = false;

                                if (totalDurations) {
                                    hours = Math.floor(totalDurations.asHours()).toString();
                                    minutes = totalDurations.minutes().toString();

                                    isSuffisent = (() => {
                                        if (employmentInfo?.monthlyWorkHours) {
                                            let hoursToWork = Number(employmentInfo.monthlyWorkHours) / 4
                                            return totalDurations.asHours() >= hoursToWork
                                        }
                                        return false
                                    })()

                                    formattedDuration = (() => {
                                        let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                        let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                                        return `${formattedHours} ${formattedMinutes}`
                                    })();
                                }
                                return <span
                                    className={cn(!isSuffisent && "text-red-500")}
                                >{formattedDuration}</span>
                            })()}</TableCell>
                        </TableRow>
                    </WorkingPlanBody>

                    <ErrorMessageList errors={errors.detailErrors}/>
                    <ErrorMessageList errors={errors.nonFieldErrors}/>

                    <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                        <DialogClose ref={closeModal as any} asChild>
                            <Button className="hidden">close</Button>
                        </DialogClose>
                        <Button
                            variant="outline"
                            onClick={() => setIsValid(!isValidated)}
                        ><FormattedMessage id={"button.back"} defaultMessage={"Back"}/></Button>
                        <Button
                            variant="taimDefault"
                            type="submit"
                        ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                    </DialogFooter>
                </div>
            )}
        </form>
    </Form>
    )
}