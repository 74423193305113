import React, {useContext, useState} from "react";
import {
    Activity,
    AttendanceFormatted,
    attendancesUrl,
    BreaktimeActivityType,
    CheckInActivityType,
    CheckOutActivityType, ServiceActivityType
} from "AttendanceManagement/Controllers/attendances-controller";
import {ArrowCircleDownLeft, ArrowCircleUpRight, PauseCircle, Airplane, Clock, FirstAidKit, Calendar} from "@phosphor-icons/react";
import {nullOrUndefined} from "Core/constants/variables";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "components/dropdown-menu";
import {Button} from "components/button";
import {MoreHorizontal} from "lucide-react";
import {toast} from "components/use-toast";
import {ManageAttendance} from "./manage-attendance";
import {Service} from "ProjectManagement/Controller/services-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {FormattedMessage, useIntl} from "react-intl";
import {
    Dialog, DialogClose,
    DialogContent,
    DialogDescription, DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "components/dialog";
import {TaimModal} from "../../../Core/components/taim-modal";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";


type CheckInActivityComponentType = {
    services: Service[]
    user: {
        userUUID: string
        userName: string
    }
    activities: Activity[]
    rePopulateRecord: (userUUID: string) => void
    employeeAttendance: AttendanceFormatted | null
    
} & CheckInActivityType

export const CheckInActivity: React.FC<CheckInActivityComponentType> = (
    {
        services,
        user,
        activities,
        rePopulateRecord,
        employeeAttendance,
        ...activity
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const deleteAttendance = (id: string) => {
        axiosInstance.delete(attendancesUrl + `${user.userUUID}/attendances/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                rePopulateRecord(user.userUUID);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }
    const checkOut = activities.find(_ => _.type === "checkOut" && _.pk === activity.pk) as CheckOutActivityType

    return (
        <>
            {permissionContext.isAdmin && (
                <div className="w-full flex justify-end">
                    <AttendanceMoreActions
                        employeeCheckIn={{...activity}}
                        employeeCheckOut={checkOut}
                        services={services}
                        rePopulateRecord={rePopulateRecord}
                        employeeAttendance={employeeAttendance}
                        user={user}
                        handleDelete={() => deleteAttendance(activity.pk)}
                    />
                </div>
            )}
            <div className="flex flex-row justify-between my-4">
                <div className="flex flex-row items-center gap-2">
                    <ArrowCircleDownLeft size={28} color={"gray"}/>
                    <div className="tewt-sm flex flex-col">
                        <span className="text-gray-500">Check In</span>
                        <span className="font-semibold">{activity?.time ?? nullOrUndefined}</span>
                    </div>
                </div>
                <span>{activity?.location ?? nullOrUndefined}</span>
            </div>
        </>
    )
}

export const CheckOutActivity: React.FC<CheckOutActivityType> = (
    {...activity}
) => {
    return (
        <div className="flex flex-row justify-between my-4">
            <div className="flex flex-row items-center gap-2">
                <ArrowCircleUpRight size={28} color={"gray"}/>
                <div className="tewt-sm flex flex-col">
                    <span className="text-gray-500">Check Out</span>
                    <span className="font-semibold">{activity?.time ?? nullOrUndefined}</span>
                </div>
            </div>
            <span>{activity?.location ?? nullOrUndefined}</span>
        </div>
    )
}

export const BreakActivity: React.FC<BreaktimeActivityType> = (
    {...activity}
) => {
    return (
        <div className="flex flex-row justify-between my-4">
            <div className="flex flex-row items-center gap-2">
                <PauseCircle size={28} color={"gray"}/>
                <div className="tewt-sm flex flex-col">
                    <span className="text-gray-500">Break</span>
                    <span className="font-semibold">
                        {activity?.startTime ?? nullOrUndefined}
                        {" - "}
                        {activity?.endTime ?? nullOrUndefined}
                    </span>
                </div>
            </div>
            <span>{activity?.duration ?? nullOrUndefined}</span>
        </div>
    )
}

export const ServiceActivity: React.FC<ServiceActivityType> = (
    {...service}
) => {
    return (
        <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
                <div className="grid grid-cols-2 h-full w-[23px] divide-x-2 divide-gray-300">
                    <div></div>
                    <div></div>
                </div>
                <div className="tewt-sm flex flex-col py-1">
                    <span className="text-gray-500">{service.name}</span>
                    <span className="font-semibold">
                        {service?.startTime ?? nullOrUndefined}
                        {" - "}
                        {service?.endTime ?? nullOrUndefined}
                    </span>
                </div>
            </div>
            <span>{service?.duration ?? nullOrUndefined}</span>
        </div>
    )
}

export const VacationActivity: React.FC<any> = (activity) => {
    return (
        <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
                <Airplane size={28} color="gray"/>
                <div className="flex flex-col">
                    <span className="text-gray-500">
                        <FormattedMessage 
                            id="attendance.activities.vacation" 
                            defaultMessage="Vacation"
                        />
                    </span>
                    <span className="font-semibold">{activity.startTime} - {activity.endTime}</span>
                </div>
            </div>
            <span>{activity.duration}</span>
        </div>
    )
}

export const CompensatoryTimeOffActivity: React.FC<any> = (activity) => {
    return (
        <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
                <Clock size={28} color="gray"/>
                <div className="flex flex-col">
                    <span className="text-gray-500">
                        <FormattedMessage 
                            id="attendance.activities.compensatoryTimeOff" 
                            defaultMessage="Compensatory Time Off"
                        />
                    </span>
                    <span className="font-semibold">{activity.startTime} - {activity.endTime}</span>
                </div>
            </div>
            <span>{activity.duration}</span>
        </div>
    )
}

export const SickNoteActivity: React.FC<any> = (activity) => {
    return (
        <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
                <FirstAidKit size={28} color="gray"/>
                <div className="flex flex-col">
                    <span className="text-gray-500">
                        <FormattedMessage 
                            id="attendance.activities.sickLeave" 
                            defaultMessage="Sick Leave"
                        />
                    </span>
                    <span className="font-semibold">{activity.startTime} - {activity.endTime}</span>
                </div>
            </div>
            <span>{activity.duration}</span>
        </div>
    )
}

export const PublicHolidayActivity: React.FC<any> = (activity) => {
    return (
        <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
                <Calendar size={28} color="gray"/>
                <div className="flex flex-col">
                    <span className="text-gray-500">
                        <FormattedMessage 
                            id="public.holiday" 
                            defaultMessage="Public Holiday"
                        />
                    </span>
                    <span className="font-semibold">{activity.startTime} - {activity.endTime}</span>
                </div>
            </div>
            <span>{activity.duration}</span>
        </div>
    )
}

export const PublicHolidayBreakActivity: React.FC<any> = (activity) => {
    return (
        <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
                <PauseCircle size={28} color="gray"/>
                <div className="flex flex-col">
                    <span className="text-gray-500">
                        <FormattedMessage 
                            id="attendance.activities.publicHolidayBreak" 
                            defaultMessage="Holiday Break"
                        />
                    </span>
                    <span className="font-semibold">{activity.startTime} - {activity.endTime}</span>
                </div>
            </div>
            <span>{activity.duration}</span>
        </div>
    )
}

type AttendanceMoreActionsType = {
    employeeCheckIn: CheckInActivityType
    employeeCheckOut: CheckOutActivityType
    services: Service[]
    handleDelete: Function
    rePopulateRecord: (userUUID: string) => void
    employeeAttendance: AttendanceFormatted | null
    user: {
        userUUID: string
        userName: string
    }
}

const AttendanceMoreActions: React.FC<AttendanceMoreActionsType> = (
    {
        employeeCheckIn,
        employeeCheckOut,
        services,
        rePopulateRecord,
        employeeAttendance,
        user,
        handleDelete
    }
) => {
    const intl = useIntl();
    const editName = intl.formatMessage({id: "action.name.edit", defaultMessage: "edit"});
    const deleteName = intl.formatMessage({id: "action.name.delete", defaultMessage: "delete"});
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [editOpen, setEditOpen] = useState(false);

    const handleEditOpen = () => {
        if (editOpen) {
            rePopulateRecord(user.userUUID)
        }
        setEditOpen(!editOpen)
    }

    const handleDropdownOpen = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <DropdownMenu open={dropdownOpen} onOpenChange={handleDropdownOpen} modal={false}>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <Dialog open={editOpen} onOpenChange={handleEditOpen}>
                    <DialogTrigger className="w-full">
                        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>{editName}</DropdownMenuItem>
                    </DialogTrigger>
                    <DialogContent className={"min-w-fit"}>
                        <DialogHeader>
                            <DialogTitle className="text-2xl">
                                <FormattedMessage id={"attendance.attendances.editAttendance"} defaultMessage={"Edit Attendances"}/>
                            </DialogTitle>
                        </DialogHeader>
                        <ManageAttendance
                            user={user}
                            employeeCheckIn={employeeCheckIn}
                            employeeCheckOut={employeeCheckOut}
                            services={services}
                            rePopulateRecord={rePopulateRecord}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog>
                    <DialogTrigger className="w-full">
                        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>{deleteName}</DropdownMenuItem>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader className={"gap-4"}>
                            <DialogTitle className={"text-2xl"}>
                                <FormattedMessage id={"toast.areYouSure"} defaultMessage={"Are you sure ?"}/>
                            </DialogTitle>
                            <DialogDescription>
                                <FormattedMessage id={"toast.areYouSure.description"} defaultMessage={"This action can not be undone."}/>
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose asChild>
                                <Button variant="outline">
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <DialogClose asChild>
                                <Button
                                    variant="destructive"
                                    type="submit"
                                    onClick={() => {
                                        handleDelete();
                                        handleDropdownOpen();
                                    }}
                                ><FormattedMessage id={"action.name.delete"} defaultMessage={"Delete"}/></Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}