import {FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../../components/form";
import {FormattedMessage, useIntl} from "react-intl";
import {Input} from "../../components/input";
import React, {SetStateAction, useEffect, useRef, useState} from "react";
import {UseFormReturn} from "react-hook-form";
import * as z from "zod";
import {createEmployeeFormSchemaFrontend} from "./form-employee-create";
import SearchInput from "../../Core/components/search-input";
import {DatePicker} from "../../components/date-picker";
import {EmploymentType, federalState, Role} from "../Controllers/employee-controller";
import {employeeManagementUrl} from "../index";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {Switch} from "../../components/switch";
import {Select, SelectContent, SelectItem, SelectNull, SelectTrigger, SelectValue} from "../../components/select";
import {getYearsArray, nullOrUndefined} from "../../Core/constants/variables";
import moment from "moment/moment";
import DetailViewCard from "../../Core/components/detail-view-card";
import FormTitle from "../../Core/components/form-title";
import {Button} from "../../components/button";
import {MagicWand, Trash} from "@phosphor-icons/react";
import {WorkingPlanBody} from "../../Core/WorkingPlan/components";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/table";
import {TimePicker, TimePickerRef} from "../../components/date-time-picker/time-picker";
import {useWorkingPlan} from "../../Core/WorkingPlan/use-working-plan";
import {TWorkingPlanBreak} from "../../Core/WorkingPlan/interfaces";
import {TimeValue} from "react-aria";
import {FieldErrors} from "../../Core/functions/use-server-errors";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../../components/accordion";
import { TaimInfo } from "Core/components/taim-info";


const DROPDOWN_YEARS = getYearsArray()

type WorkingPlanSchemaType = {
    pk?: string
    day: number
    dayName: string
    startTime: TimeValue | null | undefined
    endTime: TimeValue | null | undefined
    checked: boolean
    breaktimes: TWorkingPlanBreak[]
    breaktimeHelper: TWorkingPlanBreak
}

type WorkingPlanDurationSchemaType = {
    day: number
    duration: moment.Duration
    formattedDuration: string
    breakDuration: moment.Duration
    formattedBreakDuration: string
    workingDuration: moment.Duration
    formattedWorkingDuration: string
}

const WORKING_PLAN_DURATIONS: WorkingPlanDurationSchemaType[] = [
    {
        day: 1,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 2,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 3,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 4,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 5,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 6,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    },
    {
        day: 7,
        duration: moment.duration(0),
        formattedDuration: "0",
        breakDuration: moment.duration(0),
        formattedBreakDuration: "00h 00m",
        workingDuration: moment.duration(0),
        formattedWorkingDuration: "00h 00m"
    }
]

export const EmployeeDataForm: React.FC<{form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>}> = ({form}) => {
    const intl = useIntl();
    return <>
        <FormTitle
            title={intl.formatMessage({
                id: "employees.forms.createEmployee.employeeData",
                defaultMessage: "Employee Data"
            })}
        />
        <FormField
            control={form.control}
            name="employeeData.firstName"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.employeeData.firstName"} defaultMessage={"First Name"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "employees.forms.createEmployee.employeeData.firstName",
                                defaultMessage: "first name"
                            })}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="employeeData.lastName"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.employeeData.lastName"} defaultMessage={"Last Name"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "employees.forms.createEmployee.employeeData.lastName",
                                defaultMessage: "last name"
                            })}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="employeeData.email"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.employeeData.email"} defaultMessage={"Email"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "employees.forms.createEmployee.employeeData.email",
                                defaultMessage: "email address"
                            })}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="employeeData.phoneNumber"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.employeeData.phoneNumber"} defaultMessage={"Phone Number"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "employees.forms.createEmployee.employeeData.phoneNumber",
                                defaultMessage: "phone number"
                            })}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    </>
}


type ProfessionalDataForm = {
    form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>
}

export const ProfessionalDataForm: React.FC<ProfessionalDataForm> = ({form}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [roles, setRoles] = useState<Role[]>([]);
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([]);
    const [federalStates, setFederalStates] = useState<federalState[]>([]);


    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchFederalStates = () => {
        axiosInstance.get(employeeManagementUrl + 'states/')
            .then((res) => {
                setFederalStates(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchRoles();
        fetchFederalStates();
        fetchEmployeeTypes();
    }, []);

    return <>
        <FormTitle title={
            intl.formatMessage({
                id: "employees.forms.createEmployee.professionalData",
                defaultMessage: "Professional Data"
            })
        }/>
        <FormField
            name={"personalNumber"}
            render={({field}) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.employeeData.personalNumber"} defaultMessage={"Personal Number"}/> <span className="text-xs text-slate-500">(optional)</span>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={intl.formatMessage({
                                id: "employees.forms.createEmployee.employeeData.personalNumber",
                                defaultMessage: "Personal number"
                            })}
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="professionalData.roleID"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.professionalData.role"} defaultMessage={"Role"}/>
                    </FormLabel>
                    <SearchInput
                        title={intl.formatMessage({
                            id: "employees.forms.createEmployee.professionalData.role",
                            defaultMessage: "Role"
                        })}
                        values={roles?.map((role, index) => {
                            return {
                                key: role.name,
                                value: String(role.pk)
                            }
                        })}
                        value={field.value}
                        onChange={field.onChange}
                    />
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="professionalData.employmentTypeID"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.professionalData.employmentType"} defaultMessage={"Employment Type"}/>
                    </FormLabel>
                    <SearchInput
                        title={intl.formatMessage({
                            id: "employees.forms.createEmployee.professionalData.employmentType",
                            defaultMessage: "Employment Type"
                        })}
                        values={employmentTypes?.map((employmentType, index) => {
                            return {
                                key: employmentType.type,
                                value: String(employmentType.pk)
                            }
                        })}
                        value={field.value}
                        onChange={field.onChange}
                    />
                    <FormMessage/>
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="professionalData.entranceDate"
            render={({ field }) => (
                <FormItem className="flex flex-col">
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.professionalData.entranceDate"} defaultMessage={"Entrance Date"}/>
                    </FormLabel>
                    <FormControl>
                        <DatePicker date={field.value} setDate={field.onChange}/>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="professionalData.stateCode"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.professionalData.federalState"} defaultMessage={"Federal State"}/>
                    </FormLabel>
                    <SearchInput
                        title={intl.formatMessage({
                            id: "employees.forms.createEmployee.professionalData.federalState",
                            defaultMessage: "Federal State"
                        })}
                        values={federalStates.map((federalState, index) => {
                            return {
                                key: federalState.stateName,
                                value: federalState.stateCode
                            }
                        }) ?? []}
                        value={field.value}
                        onChange={field.onChange}
                    />
                    <FormMessage />
                </FormItem>
            )}
        />
    </>
}

type VacationDataForm = {
    form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>
    hasCarryover: boolean,
    hasDifferentExpiryDate: boolean
}

export const VacationDataForm: React.FC<VacationDataForm> = ({form, hasCarryover, hasDifferentExpiryDate}) => {
    const intl = useIntl();

    return <>
        <FormTitle
            title={intl.formatMessage({
                id: "employees.forms.createEmployee.vacationData",
                defaultMessage: "Vacation Data"
            })}
        />
        <FormField
            control={form.control}
            name="vacationData.vacationDays"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.professionalData.vacation"} defaultMessage={"Vaction Days Per Year"}/>
                    </FormLabel>
                    <FormControl>
                        <Input placeholder="0" type="number" min="0" max="365" {...field}/>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="vacationData.hasCarryover"
            render={({ field }) => (
                <FormItem className="flex flex-col space-y-4">
                    <span className="text-sm">
                        <FormattedMessage
                            id={"employees.forms.createEmployee.carryoverSentence"}
                            defaultMessage={"Carry over vacation days from the previous year to the current year?"}
                        />
                    </span>
                    <FormControl>
                        <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                            <Switch
                                className="data-[state=checked]:bg-green-500"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                            />
                            {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        {hasCarryover ? (
            <FormField
                control={form.control}
                name="vacationData.manualCarryoverDays"
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>
                            <FormattedMessage id={"employees.forms.createEmployee.manualCarryoverDays"} defaultMessage={"Carry Over Days"}/>
                        </FormLabel>
                        <FormControl>
                            <Input placeholder="0" type="number" {...field}/>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        ) : null}

        <FormField
            control={form.control}
            name="vacationData.hasDifferentExpiryDate"
            render={({ field }) => (
                <FormItem className="flex flex-col space-y-4">
                    <span className="text-sm">
                        <FormattedMessage
                            id={"employees.forms.createEmployee.expiryDateSentence"}
                            defaultMessage={"Should vacation carryovers expire at a different time?"}
                        />
                    </span>
                    <FormControl>
                        <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                            <Switch
                                className="data-[state=checked]:bg-green-500"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                            />
                            {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        {hasDifferentExpiryDate ? (
            <FormField
                control={form.control}
                name="vacationData.expiryDate"
                render={({ field }) => (
                    <FormItem className="flex flex-col space-y-4">
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.forms.createEmployee.expiryDate"}
                                defaultMessage={"Expiry Date"}
                            />
                        </FormLabel>
                        <FormControl>
                            <DatePicker date={field.value} setDate={field.onChange}/>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        ) : null}
    </>
}


type WorkingTimeAccountDataForm = {
    form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>
    errors: {fieldErrors: FieldErrors, detailErrors: string[] | undefined, nonFieldErrors: string[] | undefined}
    hasWorkingTimeAccount: boolean
    hasBalanceBoundary: boolean
}

export const WorkingTimeAccountDataForm: React.FC<WorkingTimeAccountDataForm> = ({form, errors, hasWorkingTimeAccount, hasBalanceBoundary}) => {
    const intl = useIntl();
    const months = moment.months();

    return <>
        <FormTitle
            title={intl.formatMessage({
                id: "employees.forms.createEmployee.working_time_account",
                defaultMessage: "Working Time Account"
            })}
        />
        <FormField
            control={form.control}
            name="workingTimeAccount.hasWorkingTimeAccount"
            render={({ field }) => (
                <FormItem className="flex space-x-4 justify-between border rounded-lg px-4 py-2">
                    <div>
                        <FormLabel>
                            <FormattedMessage
                                id={"employees.forms.createEmployee.working_time_account"}
                                defaultMessage={"Working Time Account"}
                            />
                        </FormLabel>
                        <FormDescription>
                            <FormattedMessage
                                id={"employees.forms.createEmployee.working_time_account.sentence"}
                                defaultMessage={"If this employee needs a working time account, please enable this."}
                            />
                        </FormDescription>
                    </div>
                    <FormControl>
                        <div>
                            <Switch
                                className="data-[state=checked]:bg-green-500"
                                checked={field.value}
                                onCheckedChange={field.onChange}
                            />
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        {hasWorkingTimeAccount ? (
            <div className="flex flex-col space-y-4 p-4 border rounded-lg">
                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"start.date"}
                            defaultMessage={"Start Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="workingTimeAccount.workingTimeAccountStatusStartMonthDate.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                            defaultValue={''}
                                        >
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="workingTimeAccount.workingTimeAccountStatusStartMonthDate.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                        >
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"end.date"}
                            defaultMessage={"End Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="workingTimeAccount.workingTimeAccountStatusEndMonthDate.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="workingTimeAccount.workingTimeAccountStatusEndMonthDate.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                {/*{errors.fieldErrors["workingTimeAccountStatusStartMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["workingTimeAccountStatusStartMonthDate"]}/>}
                {errors.fieldErrors["workingTimeAccountStatusEndMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["workingTimeAccountStatusEndMonthDate"]}/>}*/}
            </div>
        ) : null}

        {hasWorkingTimeAccount ? (
            <>
                <FormField
                    control={form.control}
                    name="workingTimeAccount.hasBalanceBoundary"
                    render={({ field }) => (
                        <FormItem className="flex space-x-4 justify-between border rounded-lg px-4 py-2">
                            <div>
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.balance_boundary"}
                                        defaultMessage={"Balance Boundary"}
                                    />
                                </FormLabel>
                                <FormDescription>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.balance_boundary.sentence"}
                                        defaultMessage={"if you want to add a balance boundary, please enable this."}
                                    />
                                </FormDescription>
                            </div>
                            <FormControl>
                                <div>
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasBalanceBoundary ? (
                    <div className="flex flex-col space-y-4 p-4 border rounded-lg">
                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"lower.limit"}
                                    defaultMessage={"Lower Limit"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryLowerLimit.hours"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"hours"}
                                                    defaultMessage={"Hours"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                                    min={0}
                                                    type={"number"}
                                                    onClick={(e) => e.currentTarget.select()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryLowerLimit.minutes"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"minutes"}
                                                    defaultMessage={"Minutes"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({id: "set.minutes", defaultMessage: "Set minutes"})}
                                                    min={0}
                                                    max={59}
                                                    type={"number"}
                                                    onClick={(e) => e.currentTarget.select()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"upper.limit"}
                                    defaultMessage={"Upper Limit"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryUpperLimit.hours"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"hours"}
                                                    defaultMessage={"Hours"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                                    min={0}
                                                    type={"number"}
                                                    onClick={(e) => e.currentTarget.select()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryUpperLimit.minutes"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"minutes"}
                                                    defaultMessage={"Minutes"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={intl.formatMessage({id: "set.minutes", defaultMessage: "Set minutes"})}
                                                    min={0}
                                                    max={59}
                                                    type={"number"}
                                                    onClick={(e) => e.currentTarget.select()}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"start.date"}
                                    defaultMessage={"Start Date"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryStartMonthDate.year"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"year"}
                                                    defaultMessage={"Year"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {DROPDOWN_YEARS.map((year) => (
                                                            <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryStartMonthDate.month"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"month"}
                                                    defaultMessage={"Month"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {months.map((month, index) => (
                                                            <SelectItem value={index.toString()}>{month}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"end.date"}
                                    defaultMessage={"End Date"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryEndMonthDate.year"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"year"}
                                                    defaultMessage={"Year"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {DROPDOWN_YEARS.map((year) => (
                                                            <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccount.balanceBoundaryEndMonthDate.month"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"month"}
                                                    defaultMessage={"Month"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {months.map((month, index) => (
                                                            <SelectItem value={index.toString()}>{month}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        {/*{errors.fieldErrors["balanceBoundaryStartMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryStartMonthDate"]}/>}
                        {errors.fieldErrors["balanceBoundaryEndMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryEndMonthDate"]}/>}
                        {errors.fieldErrors["balanceBoundaryLowerLimit"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryLowerLimit"]}/>}
                        {errors.fieldErrors["balanceBoundaryUpperLimit"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryUpperLimit"]}/>}*/}
                    </div>
                ) : null}
            </>
        ) : null}
    </>
}

type AccountAndPasswordDataForm = {
    form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>
}

export const AccountAndPasswordDataForm: React.FC<AccountAndPasswordDataForm> = ({form}) => {
    const intl = useIntl();

    return <>
        <FormTitle title={
            intl.formatMessage({
                id: "employees.forms.createEmployee.account_and_password",
                defaultMessage: "Account & Password"
            })
        }/>
        <FormField
            control={form.control}
            name="accountAndPassword.nfcUUID"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Nfc uuid <span className="text-xs text-slate-500">(optional)</span></FormLabel>
                    <FormControl>
                        <Input placeholder="Nfc uuid" {...field}/>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <div className="flex flex-row">
            <FormField
                control={form.control}
                name="accountAndPassword.isActive"
                render={({field}) => (
                    <FormItem className="w-1/2">
                        <FormControl>
                            <div className="flex flex-row items-center gap-2">
                                <Switch
                                    className="data-[state=checked]:bg-green-500"
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                                <FormLabel className="text-xs text-slate-500">{field.value ? (
                                    <FormattedMessage id={"employees.forms.createEmployee.account.activated"} defaultMessage={"Activated"}/>
                                ) : (
                                    <FormattedMessage id={"employees.forms.createEmployee.account.deactivated"} defaultMessage={"Deactivated"}/>
                                )}</FormLabel>
                            </div>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="accountAndPassword.isTestUser"
                render={({field}) => (
                    <FormItem className="w-1/2">
                        <FormControl>
                            <div className="flex flex-row items-center gap-2">
                                <Switch
                                    className="data-[state=checked]:bg-green-500"
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                                <FormLabel className="text-xs text-slate-500">
                                    <FormattedMessage id={"employees.forms.createEmployee.account.isTestUser"} defaultMessage={"Test User"}/>
                                </FormLabel>
                            </div>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
        <FormField
            control={form.control}
            name="accountAndPassword.password"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.password"} defaultMessage={"Password"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={
                                intl.formatMessage({
                                    id: "employees.forms.createEmployee.password",
                                    defaultMessage: "Password"
                                })
                            }
                            type="password"
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
        <FormField
            control={form.control}
            name="accountAndPassword.confirmPassword"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>
                        <FormattedMessage id={"employees.forms.createEmployee.confirmPassword"} defaultMessage={"Confirm Password"}/>
                    </FormLabel>
                    <FormControl>
                        <Input
                            placeholder={
                                intl.formatMessage({
                                    id: "employees.forms.createEmployee.confirmPassword",
                                    defaultMessage: "Confirm Password"
                                })
                            }
                            type="password"
                            {...field}
                        />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    </>
}


type WorkingPlanDataForm = {
    form: UseFormReturn<z.infer<typeof createEmployeeFormSchemaFrontend>>
    startingDate?: Date
    setStartingDate: React.Dispatch<SetStateAction<Date | undefined>>
    workingPlan: WorkingPlanSchemaType[]
    setWorkingPlan: React.Dispatch<SetStateAction<WorkingPlanSchemaType[]>>
}

export const WorkingPlanDataForm: React.FC<WorkingPlanDataForm> = ({form, startingDate, setStartingDate, workingPlan, setWorkingPlan}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([]);
    const [workingPlanDurations, setWorkingPlanDurations] = useState<WorkingPlanDurationSchemaType[]>(WORKING_PLAN_DURATIONS);
    const [totalDurations, setTotalDurations] = useState<moment.Duration | null>(moment.duration(0));
    const {createWithAI, handleSwitchChange} = useWorkingPlan(setWorkingPlan);
    const additionalFields = [
        intl.formatMessage({id: 'total_duration', defaultMessage: 'Total Duration'}),
        intl.formatMessage({id: 'break_duration', defaultMessage: 'Break Duration'}),
        intl.formatMessage({id: 'working_duration', defaultMessage: 'Working Duration'}),
    ]

    const startTimePickerRef = useRef<TimePickerRef>(null);
    const endTimePickerRef = useRef<TimePickerRef>(null);
  
    const resetTimePickers = () => {
      startTimePickerRef.current?.reset(); // Call the reset method
      endTimePickerRef.current?.reset(); // Call the reset method
    };
  
    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchEmployeeTypes();
    }, []);

    useEffect(() => {
        setWorkingPlanDurations((prevState) => {
            return prevState.map((elem) => {
                let currentDay = workingPlan.find(day => elem.day === day.day)

                if (!currentDay) return elem;

                let startTime = currentDay ? moment().set({
                    hours: currentDay.startTime?.hour,
                    minutes: currentDay.startTime?.minute,
                }) : null;
                let endTime = currentDay ? moment().set({
                    hours: currentDay.endTime?.hour,
                    minutes: currentDay.endTime?.minute,
                }) : null;
                let hours = "00h";
                let minutes = "00m";
                let formattedDuration = `${hours} ${minutes}`;
                let duration: moment.Duration = moment.duration(0);

                let breakDurationHours = "00h";
                let breakDurationMinutes = "00m";
                let formattedBreakDuration = `${breakDurationHours} ${breakDurationMinutes}`;
                let breakDuration: moment.Duration = moment.duration(0);
                
                let workingDurationHours = "00h";
                let workingDurationMinutes = "00m";
                let formattedWorkingDuration = `${workingDurationHours} ${workingDurationMinutes}`;
                let workingDuration: moment.Duration = moment.duration(0);

                let isReady = !!(
                    String(currentDay.startTime?.hour ?? "").length &&
                    String(currentDay.startTime?.minute ?? "").length &&
                    String(currentDay.endTime?.hour ?? "").length &&
                    String(currentDay.endTime?.minute ?? "").length
                );

                if (
                    isReady &&
                    startTime &&
                    endTime
                ) {

                    if (startTime.isAfter(endTime)) {
                        endTime.add(1, "d")
                    }

                    duration = endTime ? moment.duration(endTime.diff(startTime)) : moment.duration(0);
                    if (duration) {
                        hours = Math.floor(duration.asHours()).toString();
                        minutes = duration.minutes().toString();

                        formattedDuration = (() => {
                            let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                            let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                            return `${formattedHours} ${formattedMinutes}`
                        })();
                    }
                    
                    
                    breakDuration = currentDay.breaktimes.reduce(
                        (acc, breakTime) => {
                            let breakTimeStartDate = new Date("2018-01-01 " + breakTime.startTimeString)
                            let breakTimeEndDate = new Date("2018-01-01 " + breakTime.endTimeString)
                            
                            if (breakTimeEndDate < breakTimeStartDate)
                                breakTimeEndDate = new Date(breakTimeEndDate.getTime() + (24 * 60 * 60 * 1000))
                            
                            return acc.add((moment.duration(breakTimeEndDate.getTime()-breakTimeStartDate.getTime())))
                        }, moment.duration(0)
                    )
                    
                    breakDurationHours = Math.floor(breakDuration.asHours()).toString().padStart(2, '0');
                    breakDurationMinutes = breakDuration.minutes().toString().padStart(2, '0');
                    formattedBreakDuration = `${breakDurationHours}h ${breakDurationMinutes}m`

                    workingDuration = duration.subtract(breakDuration)
                    workingDurationHours = Math.floor(workingDuration.asHours()).toString().padStart(2, '0');
                    workingDurationMinutes = workingDuration.minutes().toString().padStart(2, '0');
                    formattedWorkingDuration = `${workingDurationHours}h ${workingDurationMinutes}m`

                    // TODO breakDuration
                    // TODO workingDuration

                }

                return {...elem, formattedDuration, duration, formattedBreakDuration, breakDuration, formattedWorkingDuration, workingDuration};
            })
        })
    }, [workingPlan]);

    useEffect(() => {
        const totalDurationArray = workingPlanDurations.map(elem => elem.workingDuration)
        const totalDurationsCalculated = totalDurationArray.reduce((accumulator, currentValue) => {
            return accumulator?.add(currentValue)
        })

        setTotalDurations(totalDurationsCalculated)

    }, [workingPlanDurations]);

    return <>
        <div className="flex flex-col gap-4 no-scrollbar">
            <DetailViewCard
                sizeFit={true}
                rows={[
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.typeOfEmployment",
                            defaultMessage: "Type Of Employment"
                        }),
                        value: employmentTypes.find((employmentType) => {
                            return employmentType.pk.toString() === form.getValues("professionalData.employmentTypeID")
                        })?.type ?? form.getValues("professionalData.employmentTypeID")
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.entranceDate",
                            defaultMessage: "Entrance Date"
                        }),
                        value: form.getValues("professionalData.entranceDate") ? moment(form.getValues("professionalData.entranceDate")).format("DD MMM YYYY") : nullOrUndefined
                    },
                ]}
            />
            <div className="flex flex-row justify-between items-center">
                <div>
                    <FormTitle title={intl.formatMessage({
                        id: "employees.forms.createEmployee.workingPlan.workingPlan",
                        defaultMessage: "Working Plan"
                    })}/>
                    <span className="text-slate-500 text-xs">
                        <FormattedMessage
                            id={"employees.forms.createEmployee.workingPlan.workingPlan.sentence"}
                            defaultMessage={"Configure at first the Time Plots for the working days"}
                        />
                    </span>
                </div>
                <Button
                    type="button"
                    variant={"taimDefault2"}
                    className="flex flex-row gap-2"
                    onClick={createWithAI}
                >
                    <span>
                        <FormattedMessage
                            id={"employees.forms.createEmployee.workingPlan.workingPlan.createWithAI"}
                            defaultMessage={"Create with AI"}
                        />
                    </span>
                    <MagicWand size={16} color="#233B99"/>
                </Button>
            </div>
            {/* Mimoun */}
            <WorkingPlanBody additionalFields={additionalFields}> 
                {workingPlan ? (
                    workingPlan.map((day, index) => 
                        {
                            return <>  
                                <TableRow className="border-none">
                                    <TableCell>
                                        <FormattedMessage
                                            id={day.dayName?.toLowerCase()}
                                            defaultMessage={day.dayName}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormField
                                            control={form.control}
                                            name={`workingTimePlanData.${index}.startTime`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <TimePicker
                                                            label={field.name}
                                                            isDisabled={!day?.checked}
                                                            value={day.startTime}
                                                            onChange={
                                                                (value) => {
                                                                    setWorkingPlan(prevState => (
                                                                            prevState.map(elem => 
                                                                                {
                                                                                    if (elem.day === day.day) {
                                                                                        return {
                                                                                            ...elem,
                                                                                            startTime: value
                                                                                        }
                                                                                    }
                                                                                    return  elem;
                                                                                }
                                                                            )
                                                                        )
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormField
                                            control={form.control}
                                            name={`workingTimePlanData.${index}.endTime`}
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormControl>
                                                        <TimePicker
                                                            label={field.name}
                                                            isDisabled={!day?.checked}
                                                            value={day.endTime}
                                                            onChange={(value) => {
                                                                setWorkingPlan(prevState => (
                                                                    prevState.map(elem => {
                                                                        if (elem.day === day.day) {
                                                                            return {
                                                                                ...elem,
                                                                                endTime: value
                                                                            }
                                                                        }
                                                                        return  elem;
                                                                    })
                                                                ))
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {workingPlanDurations.find(elem => elem.day === day.day)?.formattedDuration}
                                    </TableCell>
                                    <TableCell>
                                        {workingPlanDurations.find(elem => elem.day === day.day)?.formattedBreakDuration}
                                    </TableCell>
                                    <TableCell>
                                        {workingPlanDurations.find(elem => elem.day === day.day)?.formattedWorkingDuration}
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={day.checked}
                                            className="data-[state=checked]:bg-blue-900"
                                            onClick={() => {
                                                handleSwitchChange(index)
                                                setWorkingPlan(prevState => (
                                                    prevState.map(elem => {
                                                        if (elem.day === day.day) {
                                                            return {
                                                                ...elem,
                                                                startTime: null,
                                                                endTime: null,
                                                                checked: !day.checked
                                                            }
                                                        }
                                                        return elem;
                                                    })
                                                ))
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={7} className="px-4 pb-2 pt-0 bg-white">
                                        <Accordion type="single" collapsible className="w-full space-y-2">
                                            <AccordionItem value="item-1">
                                                <AccordionTrigger className={"p-0 pb-2 text-gray-500"}>
                                                    <div className="flex flex-row items-center space-x-2">
                                                        <span>
                                                            <FormattedMessage id={"employees.forms.addWorkingPlan.workingPlan.breaktimes_title"} defaultMessage={"Manage breaktimes"}/>
                                                        </span>
                                                        <TaimInfo
                                                            text={intl.formatMessage({
                                                                id:"employees.forms.addWorkingPlan.workingPlan.breaktimes_sentence",
                                                                defaultMessage:"Below you can add and remove breaktimes for each specific day on it's own."
                                                            })}
                                                        />
                                                    </div>
                                                </AccordionTrigger>
                                                <AccordionContent className="p-1">
                                                    <div className={`w-full flex flex-col gap-2`}>
                                                        <div className="flex flex-row gap-2 items-end">
                                                            <div className="flex flex-col">
                                                                <div className="text-gray-500">
                                                                    <FormattedMessage id={"start"} defaultMessage={"Start"}/>
                                                                </div>
                                                                <TimePicker
                                                                    label={"start"}
                                                                    value={day.breaktimeHelper?.startTime ? {
                                                                        hour: day.breaktimeHelper.startTime.hour,
                                                                        minute: day.breaktimeHelper.startTime.minute,
                                                                    } as TimeValue : undefined}
                                                                    onChange={(value) => {
                                                                            let start = value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                            setWorkingPlan(
                                                                                prevState => (
                                                                                    prevState.map(
                                                                                        elem => {
                                                                                            if (elem.day === day.day) {
                                                                                                return {
                                                                                                    ...elem,
                                                                                                    breaktimeHelper: {
                                                                                                        ...elem.breaktimeHelper,
                                                                                                        startTimeString: start,
                                                                                                        startTime: {
                                                                                                            hour: value?.hour,
                                                                                                            minute: value?.minute,
                                                                                                        } as TimeValue
                                                                                                        // Mimoun
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            return  elem;
                                                                                        }
                                                                                    )
                                                                                )
                                                                            )                                                                    

                                                                        }
                                                                    }
                                                                    ref={startTimePickerRef}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <div className="text-gray-500">
                                                                    <FormattedMessage id={"end"} defaultMessage={"End"}/>
                                                                </div>
                                                                <TimePicker
                                                                    label={"end"}
                                                                    value={day.breaktimeHelper?.endTime ? {
                                                                        hour: day.breaktimeHelper.endTime.hour,
                                                                        minute: day.breaktimeHelper.endTime.minute,
                                                                    } as TimeValue : undefined}
                                                                    onChange={
                                                                        (value) => {
                                                                            let end =  value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                            
                                                                            setWorkingPlan(
                                                                                prevState => (
                                                                                    prevState.map(
                                                                                        elem => {
                                                                                            if (elem.day === day.day) {
                                                                                                let startTime = day.breaktimeHelper.startTimeString ? moment(`2018-01-01 ${day.breaktimeHelper.startTimeString}`, "YYYY-MM-DD HH:mm") : null;
                                                                                                let endTime = end ? moment(`2018-01-01 ${end}`, "YYYY-MM-DD HH:mm") : null;

                                                                                                if (endTime && startTime && endTime.isBefore(startTime))
                                                                                                    endTime.add(1, "day")

                                                                                                let duration = endTime ? moment.duration(endTime.diff(startTime)) : moment.duration(0);
                                                                                                let formattedDuration = "00h 00m";
                                                                                                
                                                                                                if (duration) {
                                                                                                    let hours = Math.floor(duration.asHours()).toString();
                                                                                                    let minutes = duration.minutes().toString();
                                                                            
                                                                                                    formattedDuration = (() => {
                                                                                                        let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                                                                                        let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";
                                                                            
                                                                                                        return `${formattedHours} ${formattedMinutes}`
                                                                                                    })();
                                                                                                }

                                                                                                return {
                                                                                                    ...elem,
                                                                                                    breaktimeHelper: {
                                                                                                        ...elem.breaktimeHelper,
                                                                                                        endTimeString: end,
                                                                                                        endTime: {
                                                                                                            hour: value?.hour,
                                                                                                            minute: value?.minute,
                                                                                                        } as TimeValue,
                                                                                                        duration: {
                                                                                                            value: duration,
                                                                                                            string: formattedDuration
                                                                                                        }
                                                                                                        // Mimoun
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            return  elem;
                                                                                        }
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                    }
                                                                    ref={endTimePickerRef}
                                                                />
                                                            </div>
                                                            <Button
                                                                variant="taimDefault2"
                                                                type={"button"}
                                                                onClick={(event) => {
                                                                    if (day.breaktimeHelper?.startTime && day.breaktimeHelper?.endTime) {
                                                                        // ADD TO BREAKTIMES AND CONTINUE WITH BACKEND, MIMOUN
                                                                        setWorkingPlan(
                                                                            prevState => (
                                                                                prevState.map(
                                                                                    elem => {
                                                                                        if (elem.day === day.day) {
                                                                                            if (
                                                                                                (!elem.breaktimeHelper.startTimeString || !elem.breaktimeHelper.endTimeString) ||
                                                                                                elem.breaktimes.some(
                                                                                                    (currBreakTime) => {
                                                                                                        let breakTimeHelperStartDate = new Date("2018-01-01 " + day.breaktimeHelper.startTimeString)
                                                                                                        let breakTimeHelperEndDate = new Date("2018-01-01 " + day.breaktimeHelper.endTimeString)
                                                                                                        
                                                                                                        if (breakTimeHelperEndDate < breakTimeHelperStartDate)
                                                                                                            breakTimeHelperEndDate = new Date(breakTimeHelperEndDate.getTime() + (24 * 60 * 60 * 1000))

                                                                                                        let currBreakTimeStartDate = new Date("2018-01-01 " + currBreakTime.startTimeString)
                                                                                                        let currBreakTimeEndDate = new Date("2018-01-01 " + currBreakTime.endTimeString)
                                                                                                        
                                                                                                        if (currBreakTimeEndDate < currBreakTimeStartDate)
                                                                                                            currBreakTimeEndDate = new Date(currBreakTimeEndDate.getTime() + (24 * 60 * 60 * 1000))
                                                                                                        
                                                                                                        if (
                                                                                                            breakTimeHelperStartDate < currBreakTimeEndDate && 
                                                                                                            breakTimeHelperEndDate > currBreakTimeStartDate
                                                                                                        )
                                                                                                            return true
                                                                                                
                                                                                                        return false
                                                                                                    } 
                                                                                                )
                                                                                            )
                                                                                                return {
                                                                                                    ...elem
                                                                                                }
                                                                                            console.log("tata ", day.breaktimeHelper)
                                                                                            resetTimePickers()
                                                                                            return {
                                                                                                ...elem,
                                                                                                breaktimes: [...elem.breaktimes, {...day.breaktimeHelper}],
                                                                                                breaktimeHelper: {}

                                                                                            }
                                                                                        }
                                                                                        return  elem;
                                                                                    }
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                }}
                                                            ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                                                        </div>
                                        
                                                        <div className="flex flex-col gap-2">
                                                            <div className="border rounded-lg">
                                                                <Table>
                                                                    <TableHeader>
                                                                        <TableRow>
                                                                            <TableHead>
                                                                                <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                                                            </TableHead>
                                                                            <TableHead>
                                                                                <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                                                            </TableHead>
                                                                            <TableHead>
                                                                                <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                                                            </TableHead>
                                                                            <TableHead>
                                                                                <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                                                            </TableHead>
                                                                        </TableRow>
                                                                    </TableHeader>
                                                                    <TableBody>
                                                                        {day.breaktimes.length ? (
                                                                            day.breaktimes.map((currBreak, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{currBreak.startTimeString}</TableCell>
                                                                                        <TableCell>{currBreak.endTimeString}</TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                (
                                                                                                    () => {
                                                                                                        let currBreakStartDate = new Date("2018-01-01 " + currBreak.startTimeString)
                                                                                                        let currBreakEndDate = new Date("2018-01-01 " + currBreak.endTimeString)
                                                                                                        currBreakEndDate = currBreakEndDate < currBreakStartDate ? new Date(currBreakEndDate.getTime() + 24 * 60 * 60 * 1000) :  currBreakEndDate
                                                                                                        const diffInMilliseconds = currBreakEndDate.getTime()-currBreakStartDate.getTime()
                                                                                                        return `${String(Math.floor(diffInMilliseconds / (1000 * 60 * 60))).padStart(2, "0")}h ${String(Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0")}m`
                                                                                                    }
                                                                                                )()
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Trash
                                                                                                color={"red"}
                                                                                                size={20}
                                                                                                className="cursor-pointer"
                                                                                                onClick={() => {                                                                                     
                                                                                                        setWorkingPlan(
                                                                                                            prevState => (
                                                                                                                prevState.map(
                                                                                                                    elem => {
                                                                                                                        if (elem.day === day.day) {

                                                                                                                            return {
                                                                                                                                ...elem,
                                                                                                                                breaktimes: day.breaktimes.filter(
                                                                                                                                    (
                                                                                                                                        (breaktime) => breaktime != currBreak 
                                                                                                                                    )
                                                                                                                                )
                                                                                                                            }
                                                                                                                        }
                                                                                                                        return  elem;
                                                                                                                    }
                                                                                                                )
                                                                                                            )
                                                                                                        )                                                                                             
                                                                                                    }
                                                                                                }
                                                                                            />
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            })) : (
                                                                            <TableRow className="text-gray-500 text-xs">
                                                                                <TableCell colSpan={4} className={"text-center"}>
                                                                                    <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                            <FormMessage className="max-w-[25rem]"/>
                                                        </div>
                                                
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>

                                            {/*<AccordionItem value="item-2">
                                                <AccordionTrigger className={"p-0 pb-2 text-gray-500"}>
                                                    <div className="flex flex-row items-center space-x-2">
                                                        <span>Manage flexitimes</span>
                                                        <TaimInfo text={"Below you can add and remove flexitimes for each specific day on it's own."}/>
                                                    </div>
                                                </AccordionTrigger>
                                                <AccordionContent className="p-1">
                                                    <Label>Manage Flexitimes.</Label>
                                                </AccordionContent>
                                            </AccordionItem>*/}

                                        </Accordion>
                                    </TableCell>
                                </TableRow>
                            </>
                            // Mimoun 2
                        }
                    )
                ) : null}
                <TableRow>
                    <TableCell colSpan={5} className={"text-right"}>
                        <span className="">Total</span>
                    </TableCell>
                    {/* TODO Mimoun: Add Break Time Total, Work Time Total and Total Total */}
                    <TableCell colSpan={2}>{(() => {
                        let hours = "00h";
                        let minutes = "00m";
                        let formattedDuration = `${hours} ${minutes}`;
                        if (totalDurations) {
                            hours = Math.floor(totalDurations.asHours()).toString();
                            minutes = totalDurations.minutes().toString();

                            formattedDuration = (() => {
                                let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                                return `${formattedHours} ${formattedMinutes}`
                            })();
                        }
                        return <span>{formattedDuration}</span>
                    })()}</TableCell>
                </TableRow>
            </WorkingPlanBody>
            <DetailViewCard
                className="min-h-fit"
                rows={[
                    {jsxElement:
                        <div className="flex flex-col gap-3">
                            <div className="text-slate-700 text-base">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate"}
                                    defaultMessage={"Starting Date"}
                                />
                            </div>
                            <DatePicker
                                date={startingDate}
                                setDate={setStartingDate}
                                disabled={form.getValues('professionalData.entranceDate')} daysToDisable={"old"}
                            />
                            <span className="text-slate-500 text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate.sentence"}
                                    defaultMessage={`This plan will be active starting from {startingDate} until deleted or replaced`}
                                    values={
                                        {
                                            startingDate: moment(startingDate).format("ddd, DD MMM YYYY") ?? nullOrUndefined,
                                        }
                                    }
                                />
                            </span>
                        </div>
                    }
                ]}
            />
        </div>
    </>
}