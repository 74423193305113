import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../../components/button"
import {
    Form, FormControl, FormField, FormItem,
    FormMessage,
} from "../../../components/form"
import FormTitle from "../../../Core/components/form-title";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useReducer, useRef, useState} from "react";
import {DatePicker} from "../../../components/date-picker";
import {Switch} from "../../../components/switch";
import DetailViewCard from "../../../Core/components/detail-view-card";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../components/table";
import {MagicWand, Trash} from "@phosphor-icons/react";
import {TimePicker} from "../../../components/date-time-picker/time-picker";
import {nullOrUndefined} from "../../../Core/constants/variables";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {employeesUrl, EmploymentInfo, TWorkingPlan} from "../../Controllers/employee-controller";
import {toast} from "../../../components/use-toast";
import formatDate from "../../../Core/functions/format-date";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../../Core/functions/use-server-errors";
import ErrorMessageList from "../../../Core/components/ErrorMessageList";
import moment from "moment/moment";
import {cn} from "../../../lib/utils";
import {WORKING_PLAN_SCHEMA, workingPlanDurationSchema} from "../../../Core/WorkingPlan/constants";
import {
    TWorkingPlanBreak,
    WorkingPlanDurationSchemaType,
    WorkingPlanSchemaType
} from "../../../Core/WorkingPlan/interfaces";
import {parseObjectTimeToString, parseStringTimeToObject} from "../../../Core/WorkingPlan/functions";
import {useWorkingPlan} from "../../../Core/WorkingPlan/use-working-plan";
import {WorkingPlanBody} from "../../../Core/WorkingPlan/components";
import {TaimInfo} from "../../../Core/components/taim-info";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../../../components/accordion";
import {TimeValue} from "react-aria";

type WorkingPlanType = {
    formData: (WorkingPlanSchemaType & WorkingPlanDurationSchemaType)[],
    totalDuration: moment.Duration
    totalDurationString: string
}

const INITIAL_DURATION_STRING = "00h 00m"

export const WORKING_PLAN: WorkingPlanType = {
    formData: [
        {
            day: 1,
            dayName: "Monday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 2,
            dayName: "Tuesday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 3,
            dayName: "Wednesday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 4,
            dayName: "Thursday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 5,
            dayName: "Friday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 6,
            dayName: "Saturday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        },
        {
            day: 7,
            dayName: "Sunday",
            startTime: null,
            endTime: null,
            checked: false,
            breaktimes: [],
            breaktimeHelper: {},
            duration: moment.duration(0),
            formattedDuration: INITIAL_DURATION_STRING,
            totalDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            breakDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
            workingDuration: {
                value: moment.duration(0),
                string: INITIAL_DURATION_STRING
            },
        }
    ],
    totalDuration: moment.duration(0),
    totalDurationString: INITIAL_DURATION_STRING
}

enum WorkingPlanActionType {
    SET_VALUE = 'SET_VALUE',
    SET_TOTAL_DURATION = "SET_TOTAL_DURATION",
    CREATE_WITH_AI = "CREATE_WITH_AI",
    RESET_STATE = "RESET_STATE"
}

type TWorkingPlanState = WorkingPlanType

type TWorkingPlanAction =
    | { type: WorkingPlanActionType.SET_VALUE; payload:
        | { day: number, field: 'startTime'; value: TimeValue | undefined | null }
        | { day: number, field: 'endTime'; value: TimeValue | undefined | null }
        | { day: number, field: 'checked'; value: boolean }
        | { day: number, field: 'duration'; value: moment.Duration }
        | { day: number, field: 'formattedDuration'; value: string }
        | { day: number, field: 'breaktimeHelper'; value: TWorkingPlanBreak}
        | { day: number, field: 'breaktimes'; value: TWorkingPlanBreak; delete: boolean }
    }
    | { type: WorkingPlanActionType.SET_TOTAL_DURATION }
    | { type: WorkingPlanActionType.CREATE_WITH_AI }
    | { type: WorkingPlanActionType.RESET_STATE; payload?: TWorkingPlan }

const calculateDuration = (startTime: TimeValue | null | undefined, endTime: TimeValue | null | undefined, breaktimes?: TWorkingPlanBreak[]) : {
    duration: moment.Duration
    durationString: string
} => {
    let duration = moment.duration(0)
    let durationString: string

    let startTimeMoment = startTime ? moment().set({
        hours: startTime?.hour,
        minutes: startTime?.minute,
    }) : null;
    let endTimeMoment = endTime ? moment().set({
        hours: endTime?.hour,
        minutes: endTime?.minute,
    }) : null;
    if (startTimeMoment && endTimeMoment) {
        if (startTimeMoment.isAfter(endTime)) {
            endTimeMoment.add(1, "d")
        }

        let diff = moment.duration(endTimeMoment.diff(startTimeMoment))
        duration = diff

        if (breaktimes) {
            breaktimes.forEach(elem => {
                if (elem.startTime && elem.endTime) {
                    let breaktimeDuration = calculateDuration(elem.startTime, elem.endTime)
                    duration.subtract(breaktimeDuration.duration)
                }
            })
        }

        let h = String(diff.hours()).padStart(2, "0")
        let m = String(diff.minutes()).padStart(2, "0")
        durationString = `${h}h ${m}m`;
    } else {
        duration = moment.duration(0)
        durationString = INITIAL_DURATION_STRING
    }

    return {
        duration,
        durationString
    }
}

const calculateSumOfDurations = (durations: moment.Duration[]): {
    sumDuration: moment.Duration
    sumDurationString: string
} => {
    let sumDuration: moment.Duration = moment.duration(0)
    let sumDurationString: string

    sumDuration = durations.reduce((accumulator, currentValue) => {
        return accumulator?.add(currentValue)
    }, sumDuration)

    let h = String(Math.floor(sumDuration.asHours())).padStart(2, "0");
    let m = String(sumDuration.minutes()).padStart(2, "0");
    sumDurationString = `${h}h ${m}m`;

    return {
        sumDuration,
        sumDurationString
    }
}


const reducer = (state: TWorkingPlanState, action: TWorkingPlanAction): TWorkingPlanState => {
    switch (action.type) {
        case WorkingPlanActionType.SET_VALUE: {
            // console.log('dipatch triggered by:', action.type, action.payload)

            let newState = {
                ...state,
                formData: state.formData.map(day => {
                    let updatedDay = {...day}
                    if (day.day === action.payload.day) {
                        switch (action.payload.field) {
                            case "startTime":
                                if (updatedDay.checked) {
                                    updatedDay = {
                                        ...updatedDay,
                                        startTime: action.payload.value
                                    }
                                }
                                break
                            case "endTime":
                                if (updatedDay.checked) {
                                    updatedDay = {
                                        ...updatedDay,
                                        endTime: action.payload.value
                                    }
                                }
                                break
                            case "checked":
                                updatedDay = {
                                    ...updatedDay,
                                    checked: action.payload.value,
                                    startTime: null,
                                    endTime: null,
                                    breaktimes: [],
                                }
                                break
                            case "breaktimeHelper":
                                updatedDay = {
                                    ...updatedDay,
                                    breaktimeHelper: action.payload.value
                                }
                                break
                            case "breaktimes":
                                if (action.payload.delete) {
                                    updatedDay = {
                                        ...updatedDay,
                                        breaktimes: day.breaktimes.filter((elem) => (
                                            elem !== action.payload.value
                                        ))
                                    }
                                } else {
                                    let newBreaktime = {
                                        ...action.payload.value
                                    }

                                    const breaktimeDuration = calculateDuration(newBreaktime.startTime, newBreaktime.endTime)

                                    newBreaktime = {
                                        ...newBreaktime,
                                        duration: {
                                            value: breaktimeDuration.duration,
                                            string: breaktimeDuration.durationString
                                        }
                                    }

                                    updatedDay = {
                                        ...updatedDay,
                                        breaktimes: [...day.breaktimes, newBreaktime],
                                        breaktimeHelper: {}
                                    }
                                }
                                break
                        }

                        let breaktimes = updatedDay.breaktimes.length ? updatedDay.breaktimes : undefined
                        let currDayBreaktimeDurations = breaktimes?.map(b => b.duration?.value ?? moment.duration(0)) ?? []
                        let totalBreaktimeDuration = calculateSumOfDurations(currDayBreaktimeDurations)

                        let workingDuration = calculateDuration(updatedDay.startTime, updatedDay.endTime, breaktimes)
                        let totalDuration = calculateDuration(updatedDay.startTime, updatedDay.endTime)

                        updatedDay = {
                            ...updatedDay,
                            duration: totalDuration.duration,
                            formattedDuration: totalDuration.durationString,
                            totalDuration: {
                                value: totalDuration.duration,
                                string: totalDuration.durationString
                            },
                            breakDuration: {
                                value: totalBreaktimeDuration.sumDuration,
                                string: totalBreaktimeDuration.sumDurationString
                            },
                            workingDuration: {
                                value: workingDuration.duration,
                                string: workingDuration.durationString
                            }
                        }
                    }
                    return updatedDay
                }),
            }

            let durations = newState.formData.map(day => day.workingDuration.value)
            const {sumDuration, sumDurationString} = calculateSumOfDurations(durations)

            newState.totalDuration = sumDuration
            newState.totalDurationString = sumDurationString

            return newState
        }

        case WorkingPlanActionType.CREATE_WITH_AI: {
            let newState = {
                ...state,
                formData: state.formData.map((day) => {
                    if (day.day > 5) return day
                    let startTime = {
                        hour: 9,
                        minute: 0
                    } as TimeValue
                    let endTime = {
                        hour: 17,
                        minute: 0
                    } as TimeValue

                    day.startTime = startTime
                    day.endTime = endTime

                    day.checked = true

                    let breaktimes = day.breaktimes.length ? day.breaktimes : undefined
                    let currDayBreaktimeDurations = breaktimes?.map(b => b.duration?.value ?? moment.duration(0)) ?? []
                    let totalBreaktimeDuration = calculateSumOfDurations(currDayBreaktimeDurations)

                    let workingDuration = calculateDuration(startTime, endTime, breaktimes)
                    let totalDuration = calculateDuration(startTime, endTime)


                    day.duration = totalDuration.duration
                    day.formattedDuration = totalDuration.durationString

                    return {
                        ...day,
                        totalDuration: {
                            value: totalDuration.duration,
                            string: totalDuration.durationString
                        },
                        breakDuration: {
                            value: totalBreaktimeDuration.sumDuration,
                            string: totalBreaktimeDuration.sumDurationString
                        },
                        workingDuration: {
                            value: workingDuration.duration,
                            string: workingDuration.durationString
                        },
                    }
                })
            }

            let durations = newState.formData.map(day => day.workingDuration.value)
            const {sumDuration, sumDurationString} = calculateSumOfDurations(durations)

            newState.totalDuration = sumDuration
            newState.totalDurationString = sumDurationString

            return newState
        }

        case WorkingPlanActionType.RESET_STATE:
            if (action.payload) {
                let newState = {
                    ...state,
                    formData: state.formData.map((day, index) => {
                        const checkedDay = action.payload?.records.find((_) => Number(_.day) === day.day);
                        let updatedDay = {...state.formData[index]}

                        if (checkedDay && checkedDay.startTime && checkedDay.endTime) {
                            let breaktimes = checkedDay.breakTimeRecords?.length ? checkedDay.breakTimeRecords.map(breaktime => {
                                const breaktimeDuration = calculateDuration(breaktime.startTime, breaktime.endTime)

                                return {
                                    ...breaktime,
                                    duration: {
                                        value: breaktimeDuration.duration,
                                        string: breaktimeDuration.durationString
                                    }
                                }
                            }) : undefined
                            let currDayBreaktimeDurations = breaktimes?.map(b => b.duration?.value ?? moment.duration(0)) ?? []
                            let totalBreaktimeDuration = calculateSumOfDurations(currDayBreaktimeDurations)

                            let workingDuration = calculateDuration(
                                parseStringTimeToObject(checkedDay?.startTime),
                                parseStringTimeToObject(checkedDay?.endTime),
                                breaktimes
                            )

                            let totalDuration = calculateDuration(
                                parseStringTimeToObject(checkedDay?.startTime),
                                parseStringTimeToObject(checkedDay?.endTime),
                            )

                            updatedDay.duration = totalDuration.duration
                            updatedDay.formattedDuration = totalDuration.durationString
                            updatedDay = {
                                ...updatedDay,
                                breaktimes: breaktimes ?? [],
                                totalDuration: {
                                    value: totalDuration.duration,
                                    string: totalDuration.durationString
                                },
                                workingDuration: {
                                    value: workingDuration.duration,
                                    string: workingDuration.durationString
                                },
                                breakDuration: {
                                    value: totalBreaktimeDuration.sumDuration,
                                    string: totalBreaktimeDuration.sumDurationString
                                }
                            }
                        }

                        return {
                            ...updatedDay,
                            pk: checkedDay?.pk,
                            startTime: checkedDay?.startTime ? parseStringTimeToObject(checkedDay?.startTime) : null,
                            endTime: checkedDay?.endTime ? parseStringTimeToObject(checkedDay?.endTime) : null,
                            checked: !!checkedDay,
                        }
                    })
                }

                let durations = newState.formData.map(day => day.workingDuration.value)
                const {sumDuration, sumDurationString} = calculateSumOfDurations(durations)

                newState.totalDuration = sumDuration
                newState.totalDurationString = sumDurationString

                return newState
            }
            return {
                ...state,
                formData: state.formData.map((day) => {
                    return {
                        ...day,
                        startTime: null,
                        endTime: null,
                        checked: false,
                        breaktimes: [],
                        breaktimeHelper: {},
                        duration: moment.duration(0),
                        formattedDuration: INITIAL_DURATION_STRING
                    }
                })
            }

        case WorkingPlanActionType.SET_TOTAL_DURATION:
            return state

        default:
            return state
    }
}

const formSchema = z.object({
    startingDate: z.date().nullable().optional(),
    endingDate: z.date().nullable().optional(),
    records: z.any(),
});

type FormAddWorkingPlanProps = {
    userUUID: string
    employmentInfo?: EmploymentInfo
    reFetch?: any
    rePopulateRecord: (uuid: string) => void
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
}

export const FormAddWorkingPlan: React.FC<FormAddWorkingPlanProps>  = ({
    userUUID,
    reFetch,
    rePopulateRecord,
    reLoadWorkingTimeProgress
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startingDate: new Date(),
            records: (() => {
                return WORKING_PLAN_SCHEMA.map((day) => {
                    return {
                        pk: null,
                        day: day.day,
                        startTime: null,
                        endTime: null,
                        checked: false
                    }
                })
            })()
        },
    });
    const [startingDate, setStartingDate] = useState<Date | undefined>(new Date());
    const [endingDate, setEndingDate] = useState<Date | undefined>();
    const [workingPlan, dispatch] = useReducer(reducer, WORKING_PLAN)
    const [employmentInfos, setEmploymentInfos] = useState<EmploymentInfo>();
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const additionalFields = [
        intl.formatMessage({id: 'total_duration', defaultMessage: 'Total Duration'}),
        intl.formatMessage({id: 'break_duration', defaultMessage: 'Break Duration'}),
        intl.formatMessage({id: 'working_duration', defaultMessage: 'Working Duration'}),
    ]

    const addWorkingPlan = (data: any) => {
        axiosInstance.post(employeesUrl + `${userUUID}/working-plans/`, data)
            .then((res) => {
                setApiErrors({});
                dispatch({type: WorkingPlanActionType.RESET_STATE})
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
                reFetch(true);
                rePopulateRecord(userUUID);
                reLoadWorkingTimeProgress(true);
            })
            .catch((err) => {
                setApiErrors(err.response.data);
                if (err.response.data.hasOwnProperty('records')) {
                    const recordsErrors = err.response.data.records
                    Object.keys(recordsErrors).forEach((key) => {
                        const selectedDays = workingPlan.formData.filter((_: any) => _.checked);
                        const iterations = selectedDays?.length === recordsErrors.length ? recordsErrors.length : 0;
                        if (!selectedDays) return;
                        for (let i = 0; i <= iterations; i++) {
                            const currentDay = selectedDays[i]?.day;
                            if (currentDay && "startTime" in recordsErrors[i]) {
                                form.setError(`records.${currentDay - 1}.startTime`, {message: "Field is empty"})
                            }
                            if (currentDay && "endTime" in recordsErrors[i]) {
                                form.setError(`records.${currentDay - 1}.endTime`, {message: "Field is empty"})
                            }
                        }
                    })
                }
            })
    }

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        const data = {
            ...values,
            startingDate: formatDate(startingDate as Date),
            endingDate: formatDate(endingDate as Date),
            records: (() => {
                const filteredRecords = workingPlan.formData.filter((record: any) => record.checked)
                return filteredRecords?.map((day) => {
                    return {
                        pk: day.pk ?? null,
                        day: day.day,
                        startTime: day.startTime ? parseObjectTimeToString(day.startTime) : null,
                        endTime: day.endTime ? parseObjectTimeToString(day.endTime) : null,
                        breakTimeRecords: day.breaktimes.map((elem, index) => {
                            delete elem.timestamp
                            return {
                                pk: elem.pk ?? null,
                                startTime: elem.startTimeString,
                                endTime: elem.endTimeString
                            }
                        })
                    }
                })
            })()
        }
        addWorkingPlan(data);
    };

    const fetchEmploymentInfos = (date: string) => {
        axiosInstance.get(employeesUrl + `${userUUID}/employment-infos/fetch-by-date/`, {
                params: {date: date}
            })
            .then((res) => {
                setEmploymentInfos(res.data);
                form.clearErrors("startingDate");
            })
            .catch((err) => {
                form.setError("startingDate", {message: err.response.data?.detail});
            })
    }

    useEffect(() => {
        fetchEmploymentInfos(formatDate(startingDate as Date) ?? "");
    }, [startingDate]);

    useEffect(() => {
        dispatch({type: WorkingPlanActionType.RESET_STATE})
    }, []);

    useEffect(() => {
        // console.log(workingPlan)
        // workingPlan.formData.map(rec => console.log(rec.day,':',rec.duration.asMilliseconds()))
    }, [workingPlan]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <DetailViewCard rows={[
                {jsxElement:
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-row space-x-4">
                            <div className={"w-1/2 flex flex-col gap-2"}>
                                <div className="text-slate-700 text-base">
                                    <FormattedMessage
                                        id={"employees.forms.addWorkingPlan.startingDate"}
                                        defaultMessage={"Starting Date"}
                                    />
                                </div>
                                <DatePicker date={startingDate} setDate={setStartingDate}/>
                                <span className="text-slate-500 text-sm">
                                    <FormattedMessage
                                        id={"employees.forms.addWorkingPlan.startingDate.sentence"}
                                        defaultMessage={`This plan will be active starting from {date} until deleted or replaced`}
                                        values={{
                                            date: startingDate ? moment(startingDate).local().format('dddd, DD MMM YYYY') : nullOrUndefined,
                                        }}
                                    />
                                </span>
                            </div>
                            <div className={"w-1/2 flex flex-col gap-2"}>
                                <div className="text-slate-700 text-base">
                                    <FormattedMessage
                                        id={"employees.forms.addWorkingPlan.endingDate"}
                                        defaultMessage={"Ending Date"}
                                    />
                                </div>
                                <DatePicker date={endingDate} setDate={setEndingDate}/>
                                <span className="text-slate-500 text-sm">
                                    <FormattedMessage
                                        id={"employees.forms.addWorkingPlan.endingDate.sentence"}
                                        defaultMessage={`This plan will take an end until {date}`}
                                        values={{
                                            date: endingDate ? moment(endingDate).local().format('dddd, DD MMM YYYY') : nullOrUndefined,
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        {form.formState.errors.startingDate && <FormMessage>{String(form.formState.errors.startingDate.message)}</FormMessage>}
                    </div>
                }
            ]}/>
            <div className="flex flex-col gap-4 overflow-auto no-scrollbar">
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.typeOfEmployment",
                            defaultMessage: "Type Of Employment"
                        }),
                        value: employmentInfos?.type ?? nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.hoursPerMonth",
                            defaultMessage: "Hours Per Month"
                        }),
                        value: employmentInfos?.monthlyWorkHours ?? nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.hoursPerWeek",
                            defaultMessage: "Hours Per Week"
                        }),
                        value: employmentInfos?.monthlyWorkHours ? Number(employmentInfos.monthlyWorkHours) / 4 : nullOrUndefined
                    },
                ]}/>
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <FormTitle title={intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.workingPlan",
                            defaultMessage: "Working Plan"
                        })}/>
                        <span className="text-slate-500 text-xs">
                            <FormattedMessage
                                id={"employees.forms.addWorkingPlan.workingPlan.sentence"}
                                defaultMessage={"Configure at first the Time Plots for the working days"}
                            />
                        </span>
                    </div>
                    <Button type="button" variant={"taimDefault2"} className="flex flex-row gap-2" onClick={() => dispatch({type: WorkingPlanActionType.CREATE_WITH_AI})}>
                        <span>
                            <FormattedMessage
                                id={"employees.forms.addWorkingPlan.workingPlan.createWithAI"}
                                defaultMessage={"Create with AI"}
                            />
                        </span>
                        <MagicWand size={16} color="#233B99"/>
                    </Button>
                </div>

                <WorkingPlanBody additionalFields={additionalFields}>
                    {workingPlan ? (
                        workingPlan.formData.map((day, index) => (
                            <React.Fragment key={index}>
                            <TableRow className="border-none">
                                <TableCell className="bg-white">
                                    <FormattedMessage
                                        id={day.dayName.toLowerCase()}
                                        defaultMessage={day.dayName}
                                    /> 
                                    {/* Mimoun */}
                                </TableCell>
                                <TableCell className="bg-white">
                                    <FormField
                                        control={form.control}
                                        name={`records.${index}.startTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.startTime}
                                                        onChange={(value) => {
                                                            dispatch({
                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                payload: {
                                                                    day: day.day,
                                                                    field: "startTime",
                                                                    value: value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="bg-white">
                                    <FormField
                                        control={form.control}
                                        name={`records.${index}.endTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.endTime}
                                                        onChange={(value) => {
                                                            dispatch({
                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                payload: {
                                                                    day: day.day,
                                                                    field: "endTime",
                                                                    value: value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="bg-white">{day.totalDuration.string}</TableCell>
                                <TableCell className="bg-white">{day.breakDuration.string}</TableCell>
                                <TableCell className="bg-white">{day.workingDuration.string}</TableCell>
                                <TableCell className="bg-white">
                                    <Switch
                                        checked={day.checked}
                                        className="data-[state=checked]:bg-blue-900"
                                        onClick={() => {
                                            dispatch({
                                                type: WorkingPlanActionType.SET_VALUE,
                                                payload: {
                                                    day: day.day,
                                                    field: "checked",
                                                    value: !day.checked
                                                }
                                            })
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7} className="px-4 pb-2 pt-0 bg-white">
                                    <Accordion type="single" collapsible className="w-full space-y-2">
                                        <AccordionItem value="item-1">
                                            <AccordionTrigger className={"p-0 pb-2 text-gray-500"}>
                                                <div className="flex flex-row items-center space-x-2">
                                                    <span>
                                                        <FormattedMessage id={"employees.forms.addWorkingPlan.workingPlan.breaktimes_title"} defaultMessage={"Manage breaktimes"}/>
                                                    </span>
                                                    <TaimInfo
                                                        text={intl.formatMessage({
                                                            id:"employees.forms.addWorkingPlan.workingPlan.breaktimes_sentence",
                                                            defaultMessage:"Below you can add and remove breaktimes for each specific day on it's own."
                                                        })}
                                                    />
                                                </div>
                                            </AccordionTrigger>
                                            <AccordionContent className="p-1">
                                                <div className={`w-full flex flex-col gap-2`}>
                                                    <div className="flex flex-row gap-2 items-end">
                                                        <div className="flex flex-col">
                                                            <div className="text-gray-500">
                                                                <FormattedMessage id={"start"} defaultMessage={"Start"}/>
                                                            </div>
                                                            <TimePicker
                                                                label={"start"}
                                                                value={
                                                                    day.breaktimeHelper?.startTime ? {
                                                                        hour: day.breaktimeHelper.startTime.hour,
                                                                        minute: day.breaktimeHelper.startTime.minute,
                                                                    } as TimeValue : undefined
                                                                }
                                                                onChange={
                                                                    (value) => {
                                                                        let start = value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                        dispatch(
                                                                            {
                                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                                payload: {
                                                                                    day: day.day,
                                                                                    field: "breaktimeHelper",
                                                                                    value: {
                                                                                        ...day.breaktimeHelper,
                                                                                        startTime: value ?? undefined,
                                                                                        startTimeString: start
                                                                                    }
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div className="text-gray-500">
                                                                <FormattedMessage id={"end"} defaultMessage={"End"}/>
                                                            </div>
                                                            <TimePicker
                                                                label={"end"}
                                                                value={day.breaktimeHelper?.endTime ? {
                                                                    hour: day.breaktimeHelper.endTime.hour,
                                                                    minute: day.breaktimeHelper.endTime.minute,
                                                                } as TimeValue : undefined}
                                                                onChange={(value) => {
                                                                    let end =  value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                    dispatch({
                                                                        type: WorkingPlanActionType.SET_VALUE,
                                                                        payload: {
                                                                            day: day.day,
                                                                            field: "breaktimeHelper",
                                                                            value: {
                                                                                ...day.breaktimeHelper,
                                                                                endTime: value ?? undefined,
                                                                                endTimeString: end
                                                                            }
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="taimDefault2"
                                                            type={"button"}
                                                            onClick={(event) => {
                                                                if (day.breaktimeHelper?.startTime && day.breaktimeHelper?.endTime) {
                                                                    dispatch({
                                                                        type: WorkingPlanActionType.SET_VALUE,
                                                                        payload: {
                                                                            day: day.day,
                                                                            field: "breaktimes",
                                                                            value: {...day.breaktimeHelper, timestamp: new Date()},
                                                                            delete: false
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                                                    </div>

                                                    <FormField
                                                        control={form.control}
                                                        name={`records.${index}.breaktimes`}
                                                        render={({ field}) => (
                                                            <div className="flex flex-col gap-2">
                                                                <div className="border rounded-lg">
                                                                    <Table>
                                                                        <TableHeader>
                                                                            <TableRow>
                                                                                <TableHead>
                                                                                    <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                                                                </TableHead>
                                                                                <TableHead>
                                                                                    <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                                                                </TableHead>
                                                                                <TableHead>
                                                                                    <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                                                                </TableHead>
                                                                                <TableHead>
                                                                                    <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                                                                </TableHead>
                                                                            </TableRow>
                                                                        </TableHeader>
                                                                        <TableBody>
                                                                            {day.breaktimes.length ? (
                                                                                day.breaktimes.map((currBreak, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell>{currBreak.startTimeString}</TableCell>
                                                                                            <TableCell>{currBreak.endTimeString}</TableCell>
                                                                                            <TableCell>{currBreak?.duration?.string ?? nullOrUndefined}</TableCell>
                                                                                            <TableCell>
                                                                                                <Trash
                                                                                                    color={"red"}
                                                                                                    size={20}
                                                                                                    className="cursor-pointer"
                                                                                                    onClick={() => {
                                                                                                        dispatch({
                                                                                                            type: WorkingPlanActionType.SET_VALUE,
                                                                                                            payload: {
                                                                                                                day: day.day,
                                                                                                                field: "breaktimes",
                                                                                                                value: currBreak,
                                                                                                                delete: true
                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })) : (
                                                                                <TableRow className="text-gray-500 text-xs">
                                                                                    <TableCell colSpan={4} className={"text-center"}>
                                                                                        <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                                <FormMessage className="max-w-[25rem]"/>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </AccordionContent>
                                        </AccordionItem>

                                        {/*<AccordionItem value="item-2">
                                            <AccordionTrigger className={"p-0 pb-2 text-gray-500"}>
                                                <div className="flex flex-row items-center space-x-2">
                                                    <span>Manage flexitimes</span>
                                                    <TaimInfo text={"Below you can add and remove flexitimes for each specific day on it's own."}/>
                                                </div>
                                            </AccordionTrigger>
                                            <AccordionContent className="p-1">
                                                <Label>Manage Flexitimes.</Label>
                                            </AccordionContent>
                                        </AccordionItem>*/}

                                    </Accordion>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                        ))
                    ) : null}
                    <TableRow>
                        <TableCell colSpan={5} className={"text-right"}>
                            <span className="">Total</span>
                        </TableCell>
                        <TableCell colSpan={2}>{(() => {
                            let isSuffisent: boolean = false;

                            if (workingPlan.totalDuration) {

                                isSuffisent = (() => {
                                    if (employmentInfos?.monthlyWorkHours) {
                                        let hoursToWork = Number(employmentInfos.monthlyWorkHours) / 4
                                        return workingPlan.totalDuration.asHours() >= hoursToWork
                                    }
                                    return false
                                })()
                            }
                            return <span
                                className={cn(!isSuffisent && "text-red-500")}
                            >{workingPlan.totalDurationString}</span>
                        })()}</TableCell>
                    </TableRow>
                </WorkingPlanBody>

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button className="hidden">close</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </div>
        </form>
    </Form>
    )
}


type FormEditWorkingPlanProps = {
    userWorkingPlan: TWorkingPlan
    userUUID: string
    reFetch?: any
    rePopulateRecord: (uuid: string) => void
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
}


export const FormEditWorkingPlan: React.FC<FormEditWorkingPlanProps>  = ({
    userWorkingPlan,
    userUUID,
    reFetch,
    rePopulateRecord,
    reLoadWorkingTimeProgress
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    });
    const [workingPlan, dispatch] = useReducer(reducer, WORKING_PLAN)
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const additionalFields = [
        intl.formatMessage({id: 'total_duration', defaultMessage: 'Total Duration'}),
        intl.formatMessage({id: 'break_duration', defaultMessage: 'Break Duration'}),
        intl.formatMessage({id: 'working_duration', defaultMessage: 'Working Duration'}),
    ]

    const editWorkingPlan = (data: any) => {
        axiosInstance.put(employeesUrl + `${userUUID}/working-plans/${userWorkingPlan.pk}/`, data)
            .then((res) => {
                setApiErrors({});
                dispatch({type: WorkingPlanActionType.RESET_STATE})
                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.updatedSuccessfully", defaultMessage: "The update was completed successfully."})
                });
                reFetch(true);
                rePopulateRecord(userUUID);
                reLoadWorkingTimeProgress(true);
            })
            .catch((err) => {
                setApiErrors(err.response.data);
                if (err.response.data.hasOwnProperty('records')) {
                    const recordsErrors = err.response.data.records
                    Object.keys(recordsErrors).forEach((key) => {
                        const selectedDays = workingPlan.formData.filter((_: any) => _.checked);
                        const iterations = selectedDays?.length === recordsErrors.length ? recordsErrors.length : 0;
                        if (!selectedDays) return;
                        for (let i = 0; i <= iterations; i++) {
                            const currentDay = selectedDays[i]?.day;
                            if (currentDay && "startTime" in recordsErrors[i]) {
                                form.setError(`records.${currentDay - 1}.startTime`, {message: "Field is empty"})
                            }
                            if (currentDay && "endTime" in recordsErrors[i]) {
                                form.setError(`records.${currentDay - 1}.endTime`, {message: "Field is empty"})
                            }
                        }
                    })
                }
            })
    }


    const onSubmit = (values: z.infer<typeof formSchema>) => {
        const data = {
            ...userWorkingPlan,
            records: (() => {
                const filteredRecords = workingPlan.formData.filter((record: any) => record.checked)
                return filteredRecords?.map((day) => {
                    return {
                        pk: day.pk ?? null,
                        day: day.day,
                        startTime: day.startTime ? parseObjectTimeToString(day.startTime) : null,
                        endTime: day.endTime ? parseObjectTimeToString(day.endTime) : null,
                        breakTimeRecords: day.breaktimes.map((elem) => {
                            delete elem.timestamp
                            return {
                                pk: elem.pk ?? null,
                                startTime: elem.startTimeString,
                                endTime: elem.endTimeString
                            }
                        })
                    }
                })
            })()
        }
        editWorkingPlan(data);
    };

    useEffect(() => {
        dispatch({type: WorkingPlanActionType.RESET_STATE, payload: userWorkingPlan})
    }, []);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 overflow-auto no-scrollbar p-1">
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.typeOfEmployment",
                            defaultMessage: "Type Of Employment"
                        }),
                        value: userWorkingPlan?.employmentInfo?.type ?? nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.hoursPerMonth",
                            defaultMessage: "Hours Per Month"
                        }),
                        value: userWorkingPlan?.employmentInfo?.monthlyWorkingHours ?? nullOrUndefined
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.hoursPerWeek",
                            defaultMessage: "Hours Per Week"
                        }),
                        value: userWorkingPlan?.employmentInfo?.monthlyWorkingHours ? Number(userWorkingPlan.employmentInfo.monthlyWorkingHours) / 4 : nullOrUndefined
                    },
                ]}/>
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <FormTitle title={intl.formatMessage({
                            id: "employees.forms.addWorkingPlan.workingPlan",
                            defaultMessage: "Working Plan"
                        })}/>
                        <span className="text-slate-500 text-xs">
                            <FormattedMessage
                                id={"employees.forms.addWorkingPlan.workingPlan.sentence"}
                                defaultMessage={"Configure at first the Time Plots for the working days"}
                            />
                        </span>
                    </div>
                    <Button type="button" variant={"taimDefault2"} className="flex flex-row gap-2" onClick={() => dispatch({type: WorkingPlanActionType.CREATE_WITH_AI})}>
                        <span>
                            <FormattedMessage
                                id={"employees.forms.addWorkingPlan.workingPlan.createWithAI"}
                                defaultMessage={"Create with AI"}
                            />
                        </span>
                        <MagicWand size={16} color="#233B99"/>
                    </Button>
                </div>
                <WorkingPlanBody additionalFields={additionalFields}>
                    {workingPlan.formData ? (
                        workingPlan.formData.map((day, index) => (
                            <React.Fragment key={index}>
                            <TableRow className="border-none">
                                <TableCell className="bg-white">
                                    <FormattedMessage
                                        id={day.dayName.toLowerCase()}
                                        defaultMessage={day.dayName}
                                    />
                                </TableCell>
                                <TableCell className="bg-white">
                                    <FormField
                                        control={form.control}
                                        name={`records.${index}.startTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.startTime}
                                                        onChange={(value) => {
                                                            dispatch({
                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                payload: {
                                                                    day: day.day,
                                                                    field: "startTime",
                                                                    value: value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="bg-white">
                                    <FormField
                                        control={form.control}
                                        name={`records.${index}.endTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.endTime}
                                                        onChange={(value) => {
                                                            dispatch({
                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                payload: {
                                                                    day: day.day,
                                                                    field: "endTime",
                                                                    value: value
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell className="bg-white">{day.totalDuration.string}</TableCell>
                                <TableCell className="bg-white">{day.breakDuration.string}</TableCell>
                                <TableCell className="bg-white">{day.workingDuration.string}</TableCell>
                                <TableCell className="bg-white">
                                    <Switch
                                        checked={day.checked}
                                        className="data-[state=checked]:bg-blue-900"
                                        onClick={() => {
                                            dispatch({
                                                type: WorkingPlanActionType.SET_VALUE,
                                                payload: {
                                                    day: day.day,
                                                    field: "checked",
                                                    value: !day.checked
                                                }
                                            })
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7} className="px-4 pb-2 pt-0 bg-white">
                                    <div className="w-full flex flex-col items-center justify-center space-y-1">
                                        <Accordion type="single" collapsible className="w-full">
                                            <AccordionItem value="item-1">
                                                <AccordionTrigger className={"p-0 pb-2 text-gray-500"}>
                                                    <div className="flex flex-row items-center space-x-2">
                                                        <span>
                                                            <FormattedMessage id={"employees.forms.addWorkingPlan.workingPlan.breaktimes_title"} defaultMessage={"Manage breaktimes"}/>
                                                        </span>
                                                        <TaimInfo
                                                            text={intl.formatMessage({
                                                                id:"employees.forms.addWorkingPlan.workingPlan.breaktimes_sentence",
                                                                defaultMessage:"Below you can add and remove breaktimes for each specific day on it's own."
                                                            })}
                                                        />
                                                    </div>
                                                </AccordionTrigger>
                                                <AccordionContent className="p-1">
                                                    <div className={`w-full flex flex-col gap-2`}>
                                                        <div className="flex flex-row gap-2 items-end">
                                                            <div className="flex flex-col">
                                                                <div className="text-gray-500">
                                                                    <FormattedMessage id={"start"} defaultMessage={"Start"}/>
                                                                </div>
                                                                <TimePicker
                                                                    label={"start"}
                                                                    value={day.breaktimeHelper?.startTime ? {
                                                                        hour: day.breaktimeHelper.startTime.hour,
                                                                        minute: day.breaktimeHelper.startTime.minute,
                                                                    } as TimeValue : undefined}
                                                                    onChange={(value) => {
                                                                        let start = value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                        dispatch({
                                                                            type: WorkingPlanActionType.SET_VALUE,
                                                                            payload: {
                                                                                day: day.day,
                                                                                field: "breaktimeHelper",
                                                                                value: {
                                                                                    ...day.breaktimeHelper,
                                                                                    startTime: value ?? undefined,
                                                                                    startTimeString: start
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <div className="text-gray-500">
                                                                    <FormattedMessage id={"end"} defaultMessage={"End"}/>
                                                                </div>
                                                                <TimePicker
                                                                    label={"end"}
                                                                    value={day.breaktimeHelper?.endTime ? {
                                                                        hour: day.breaktimeHelper.endTime.hour,
                                                                        minute: day.breaktimeHelper.endTime.minute,
                                                                    } as TimeValue : null}
                                                                    onChange={(value) => {
                                                                        let end =  value ? [value.hour, value.minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':') : undefined
                                                                        dispatch({
                                                                            type: WorkingPlanActionType.SET_VALUE,
                                                                            payload: {
                                                                                day: day.day,
                                                                                field: "breaktimeHelper",
                                                                                value: {
                                                                                    ...day.breaktimeHelper,
                                                                                    endTime: value ?? undefined,
                                                                                    endTimeString: end
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <Button
                                                                variant="taimDefault2"
                                                                type={"button"}
                                                                onClick={(event) => {
                                                                    console.log("????? ", day.breaktimeHelper)
                                                                    if (day.breaktimeHelper?.startTime && day.breaktimeHelper?.endTime) {
                                                                        dispatch({
                                                                            type: WorkingPlanActionType.SET_VALUE,
                                                                            payload: {
                                                                                day: day.day,
                                                                                field: "breaktimes",
                                                                                value: {...day.breaktimeHelper, timestamp: new Date()},
                                                                                delete: false
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                            ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                                                        </div>


                                                        <FormField
                                                            control={form.control}
                                                            name={`records.${index}.breaktimes`}
                                                            render={({ field}) => (
                                                                <div className="flex flex-col gap-2">
                                                                    <div className="border rounded-lg">
                                                                        <Table>
                                                                            <TableHeader>
                                                                                <TableRow>
                                                                                    <TableHead>
                                                                                        <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                                                                    </TableHead>
                                                                                    <TableHead>
                                                                                        <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                                                                    </TableHead>
                                                                                    <TableHead>
                                                                                        <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                                                                    </TableHead>
                                                                                    <TableHead>
                                                                                        <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                                                                    </TableHead>
                                                                                </TableRow>
                                                                            </TableHeader>
                                                                            <TableBody>
                                                                                {day.breaktimes.length ? (
                                                                                    day.breaktimes.map((currBreak, index) => {
                                                                                        return (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell>{currBreak.startTimeString}</TableCell>
                                                                                                <TableCell>{currBreak.endTimeString}</TableCell>
                                                                                                <TableCell>{currBreak.duration?.string ?? INITIAL_DURATION_STRING}</TableCell>
                                                                                                <TableCell>
                                                                                                    <Trash
                                                                                                        color={"red"}
                                                                                                        size={20}
                                                                                                        className="cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            dispatch({
                                                                                                                type: WorkingPlanActionType.SET_VALUE,
                                                                                                                payload: {
                                                                                                                    day: day.day,
                                                                                                                    field: "breaktimes",
                                                                                                                    value: currBreak,
                                                                                                                    delete: true
                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    })) : (
                                                                                    <TableRow className="text-gray-500 text-xs">
                                                                                        <TableCell colSpan={4} className={"text-center"}>
                                                                                            <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </div>
                                                                    <FormMessage className="max-w-[25rem]"/>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </TableCell>
                            </TableRow>
                            </React.Fragment>
                        ))
                    ) : null}
                    <TableRow>
                        <TableCell colSpan={5} className={"text-right"}>
                            <span className="">Total</span>
                        </TableCell>
                        <TableCell colSpan={2}>{(() => {
                            let isSuffisent: boolean = false;

                            if (workingPlan.totalDuration) {
                                isSuffisent = (() => {
                                    if (userWorkingPlan?.employmentInfo?.monthlyWorkingHours) {
                                        let hoursToWork = Number(userWorkingPlan.employmentInfo.monthlyWorkingHours) / 4
                                        return workingPlan.totalDuration.asHours() >= hoursToWork
                                    }
                                    return false
                                })()
                            }
                            return <span
                                className={cn(!isSuffisent && "text-red-500")}
                            >{workingPlan.totalDurationString}</span>
                        })()}</TableCell>
                    </TableRow>
                </WorkingPlanBody>

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button className="hidden">close</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </div>
        </form>
    </Form>
    )
}
