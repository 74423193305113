export type status = "pending" | "accepted" | "rejected" |
    "Check In" | "Check Out" | "Missing" |
    "Complete" | "Incomplete" | "Pending" |
    "Offline" | "On Break" | "Working" | "Sick Leave" | "Day Off" | "Vacation" | "vacation" |
    "sickLeave" | "breakTime" | "Feiertag" | "Krankheit" | "Freizeitausgleich" | "Urlaub" | "Fehlend" |
    "Done" | "Progress" | "attendance";

export type badgeStatusVariants = "red" | "yellow" | "green" | "default" | "secondary" | "blue" | "purple"

const statusVariants = (status: status, version?: number): badgeStatusVariants => {
    let result: badgeStatusVariants;

    switch (status?.toLowerCase()) {
        case "accepted":
        case "complete":
        case "check in":
        case "done":
        case "working":
        case "attendance":
            result = "green";
            break;

        case "pending":
        case "missing":
        case "sick leave":
        case "breaktime":
        case "fehlend":
            result = "yellow";
            break;

        case "rejected":
        case "incomplete":
        case "check out":
        case "on break":
        case "sickleave":
        case "krankheit":
            result = "red";
            break;

        case "offline":
            result = "secondary";
            break;

        case "progress":
        case "vacation":
        case "urlaub":
            result = "blue";
            break;

        case "day off":
        case "feiertag":
        case "freizeitausgleich":
            result = "purple";
            break;

        default:
            result = "default";
            break;
    }

    if (version !== undefined) {
        result = `${result}${version}` as badgeStatusVariants;
    }

    return result;
};

export default statusVariants;