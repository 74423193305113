import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
    Form,
} from "../../components/form"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useEffect, useRef, useState} from "react";
import {
    employeesUrl,
} from "../Controllers/employee-controller";
import formatDate from "../../Core/functions/format-date";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {TimeValue} from "react-aria";
import {FormattedMessage, useIntl} from "react-intl";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import moment from "moment";
import {TWorkingPlanBreak} from "../../Core/WorkingPlan/interfaces";
import {transformHoursAndMinutesToDuration} from "../../Core/functions/transform-duration";
import {useSteps} from "../../Core/functions/use-steps";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {
    AccountAndPasswordDataForm,
    EmployeeDataForm,
    ProfessionalDataForm,
    VacationDataForm, WorkingPlanDataForm,
    WorkingTimeAccountDataForm
} from "./components";
import {
    FieldErrors,
    ServerErrorsType,
    useServerErrors
} from "../../Core/functions/use-server-errors";
import {WORKING_PLAN_SCHEMA} from "../../Core/WorkingPlan/constants";
import { Day } from "react-day-picker"

function formatTimeValue (time?: TimeValue | null) {
    if (time === undefined || time === null) return;

    const updatedHour = (time.hour.toString().length === 1) ? `0${time.hour}` : time.hour;
    const updatedMinute = (time.minute.toString().length === 1) ? `0${time.minute}` : time.minute;
    return `${updatedHour}:${updatedMinute}`
}

const EMPLOYEE_DATA = 'employee_data';
const PROFESSIONAL_DATA = 'professional_data';
const VACATION_DATA = 'vacation_data';
const WORKING_TIME_ACCOUNT = 'working_time_account';
const ACCOUNT_AND_PASSWORD = 'account_&_password';
const WORKING_PLAN = 'working_plan';

const STEP_INPUTS = [
    EMPLOYEE_DATA,
    PROFESSIONAL_DATA,
    VACATION_DATA,
    WORKING_TIME_ACCOUNT,
    ACCOUNT_AND_PASSWORD,
    WORKING_PLAN
];

export const createEmployeeFormSchemaFrontend = z.object({
    employeeData: z.object({
        firstName: z.any(),
        lastName: z.any(),
        email: z.any(),
        phoneNumber: z.any(),
    }),
    professionalData: z.object({
        personalNumber: z.any(),
        roleID: z.any(),
        employmentTypeID: z.any(),
        entranceDate: z.any(),
        stateCode: z.any(),
    }),
    vacationData: z.object({
        vacationDays: z.any(),
        hasDifferentExpiryDate: z.any(),
        expiryDate: z.any(),
        hasCarryover: z.any(),
        manualCarryoverDays: z.any(),
    }),
    accountAndPassword: z.object({
        nfcUUID: z.any(),
        isActive: z.any(),
        password: z.any(),
        confirmPassword: z.any(),
        isTestUser: z.any(),
    }),
    workingTimePlanData: z.any(),
    agendaData: z.any(),
    workingTimeAccount: z.object({
        hasWorkingTimeAccount: z.any(),
        workingTimeAccountStatusStartMonthDate: z.object({
            year: z.any(),
            month: z.any()
        }),
        workingTimeAccountStatusEndMonthDate: z.object({
            year: z.any(),
            month: z.any()
        }),
        hasBalanceBoundary: z.any(),
        balanceBoundaryStartMonthDate: z.object({
            year: z.any(),
            month: z.any()
        }),
        balanceBoundaryEndMonthDate: z.object({
            year: z.any(),
            month: z.any()
        }),
        balanceBoundaryUpperLimit: z.object({
            hours: z.any(),
            minutes: z.any()
        }),
        balanceBoundaryLowerLimit: z.object({
            hours: z.any(),
            minutes: z.any()
        })
    })
})

export const createEmployeeFormSchemaBackend = z.object({
    firstName: z.any(),
    lastName: z.any(),
    email: z.any(),
    phoneNumber: z.any(),
    roleID: z.any(),
    employmentTypeID: z.any(),
    entranceDate: z.any(),
    stateCode: z.any(),
    vacationDays: z.any(),
    hasDifferentExpiryDate: z.any(),
    expiryDate: z.any(),
    hasCarryover: z.any(),
    manualCarryoverDays: z.any(),
    nfcUUID: z.any(),
    isActive: z.any(),
    password: z.any(),
    confirmPassword: z.any(),
    isTestUser: z.any(),
    workingTimePlanData: z.any(),
    personalNumber: z.any(),
    agendaData: z.any(),
    hasWorkingTimeAccount: z.any(),
    workingTimeAccountStatusStartMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    workingTimeAccountStatusEndMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    hasBalanceBoundary: z.any(),
    balanceBoundaryStartMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    balanceBoundaryEndMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    balanceBoundaryUpperLimit: z.object({
        hours: z.any(),
        minutes: z.any()
    }),
    balanceBoundaryLowerLimit: z.object({
        hours: z.any(),
        minutes: z.any()
    })
})

let formKeys = createEmployeeFormSchemaFrontend.shape;
let EMPLOYEE_DATA_STEP_ERRORS =  Object.keys(formKeys.employeeData.shape);
let PROFESSIONAL_DATA_STEP_ERRORS = Object.keys(formKeys.professionalData.shape);
let VACATION_DATA_STEP_ERRORS = Object.keys(formKeys.vacationData.shape);
let WORKING_TIME_ACCOUNT_STEP_ERRORS =  Object.keys(formKeys.workingTimeAccount.shape);
let ACCOUNT_AND_PASSWORD_STEP_ERRORS = Object.keys(formKeys.accountAndPassword.shape);
let WORKING_PLAN_STEP_ERRORS = ['workingTimePlanData'];

type FormEmployeeCreateProps = {
    reFetch?: any
    defaultValues?: {
        firstName?: string
        lastName?: string
        entranceDate?: string | Date
        personalNumber?: string
        agendaData?: string
        phoneNumber?: string
        email?: string
        state?: string
        stateCode?: string
    }
    sideFunctions?: (() => void)[]
    setDialogContentClassName: (className: string) => void
}

type WorkingPlanSchemaType = {
    pk?: string
    day: number
    dayName: string
    startTime: TimeValue | null | undefined
    endTime: TimeValue | null | undefined
    checked: boolean
    breaktimes: TWorkingPlanBreak[]
    breaktimeHelper: TWorkingPlanBreak
}

const WorkingPlanSchema: WorkingPlanSchemaType[] = [
    {
        day: 1,
        dayName: "Monday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 2,
        dayName: "Tuesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 3,
        dayName: "Wednesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 4,
        dayName: "Thursday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 5,
        dayName: "Friday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 6,
        dayName: "Saturday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 7,
        dayName: "Sunday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    }
]

export const FormEmployeeCreate: React.FC<FormEmployeeCreateProps>  = ({
    reFetch,
    defaultValues,
    sideFunctions,
    setDialogContentClassName
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof createEmployeeFormSchemaFrontend>>({
        resolver: zodResolver(createEmployeeFormSchemaFrontend),
        defaultValues: {
            accountAndPassword: {
                isActive: true,
                isTestUser: false,
            },
            workingTimePlanData: (() => {
                return WorkingPlanSchema.map((day) => {
                    return {
                        pk: null,
                        day: day.day,
                        startTime: null,
                        endTime: null,
                        checked: false
                    }
                })
            })(),
            vacationData: {
                hasDifferentExpiryDate: false,
                hasCarryover: false,
            },
            ...defaultValues
        },
    });
    const closeModal = useRef<HTMLElement>();
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const stepper = useSteps(STEP_INPUTS, EMPLOYEE_DATA, true);
    const [hasWorkingTimeAccount,setHasWorkingTimeAccount] = useState(false);
    const [hasBalanceBoundary,setHasBalanceBoundary] = useState(false);
    const [hasCarryover,setHasCarryover] = useState(false);
    const [hasDifferentExpiryDate,setHasDifferentExpiryDate] = useState(false);
    const [startingDate, setStartingDate] = useState<Date | undefined>();
    const [workingPlan, setWorkingPlan] = useState<WorkingPlanSchemaType[]>(WORKING_PLAN_SCHEMA);

    const transformDataFromFrontendToBackendShape = (input: z.infer<typeof createEmployeeFormSchemaFrontend>, withWorkingPlan: boolean = false): z.infer<typeof createEmployeeFormSchemaBackend> => {
        return {
            ...input.professionalData,
            ...input.vacationData,
            ...input.employeeData,
            ...input.accountAndPassword,
            ...input.workingTimeAccount,
            expiryDate: input.vacationData.hasDifferentExpiryDate ? moment(input.vacationData.expiryDate).format("YYYY-MM-DD") : null,
            manualCarryoverDays: input.vacationData.hasCarryover ? input.vacationData.manualCarryoverDays : null,
            nfcUUID: (input.accountAndPassword.nfcUUID) ? input.accountAndPassword.nfcUUID : null,
            entranceDate: formatDate(input.professionalData?.entranceDate as Date),
            ...(input.workingTimeAccount.hasWorkingTimeAccount && {
                workingTimeAccountStatusStartMonthDate: (input.workingTimeAccount.workingTimeAccountStatusStartMonthDate.year && input.workingTimeAccount.workingTimeAccountStatusStartMonthDate.month) ? moment({
                    year: input.workingTimeAccount.workingTimeAccountStatusStartMonthDate.year,
                    month: input.workingTimeAccount.workingTimeAccountStatusStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                workingTimeAccountStatusEndMonthDate: (input.workingTimeAccount.workingTimeAccountStatusEndMonthDate.year && input.workingTimeAccount.workingTimeAccountStatusEndMonthDate.month) ? moment({
                    year: input.workingTimeAccount.workingTimeAccountStatusEndMonthDate.year,
                    month: input.workingTimeAccount.workingTimeAccountStatusEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
            }),
            ...(input.workingTimeAccount.hasBalanceBoundary && {
                balanceBoundaryStartMonthDate: (input.workingTimeAccount.balanceBoundaryStartMonthDate.year && input.workingTimeAccount.balanceBoundaryStartMonthDate.month) ? moment({
                    year: input.workingTimeAccount.balanceBoundaryStartMonthDate.year,
                    month: input.workingTimeAccount.balanceBoundaryStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryEndMonthDate: (input.workingTimeAccount.balanceBoundaryEndMonthDate.year && input.workingTimeAccount.balanceBoundaryEndMonthDate.month) ? moment({
                    year: input.workingTimeAccount.balanceBoundaryEndMonthDate.year,
                    month: input.workingTimeAccount.balanceBoundaryEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryLowerLimit : (input.workingTimeAccount.balanceBoundaryLowerLimit.hours || input.workingTimeAccount.balanceBoundaryLowerLimit.minutes)
                    ? transformHoursAndMinutesToDuration({
                        hours: input.workingTimeAccount.balanceBoundaryLowerLimit.hours,
                        minutes: input.workingTimeAccount.balanceBoundaryLowerLimit.minutes
                    })
                    : null,
                balanceBoundaryUpperLimit : (input.workingTimeAccount.balanceBoundaryUpperLimit.hours || input.workingTimeAccount.balanceBoundaryUpperLimit.minutes)
                    ? transformHoursAndMinutesToDuration({
                        hours: input.workingTimeAccount.balanceBoundaryUpperLimit.hours,
                        minutes: input.workingTimeAccount.balanceBoundaryUpperLimit.minutes
                    })
                    : null
            }),
            ...(withWorkingPlan && {
                workingTimePlanData: (() => {
                    const filteredRecords = workingPlan?.filter((record: any) => record.checked)

                    return {
                        ...filteredRecords?.reduce((acc: any, day: any) => {
                            let dayName = day.dayName?.toLowerCase();
                            acc[dayName + "TimeFrom"] = formatTimeValue(day?.startTime as TimeValue);
                            acc[dayName + "TimeTo"] = formatTimeValue(day?.endTime as TimeValue);
                            acc[dayName + "BreakTimes"] = day.breaktimes
                            return acc;
                        }, {}),
                        startingDate: moment(startingDate).local().format("YYYY-MM-DD"),
                    }
                })(),
            })
        }
    }
    
    const handleValidate = () => {
        form.clearErrors();
        let values= form.getValues();
        let data: z.infer<typeof createEmployeeFormSchemaBackend> = transformDataFromFrontendToBackendShape(values);
        let redirect = false

        if (values.professionalData.roleID === "no-value") {
            return form.setError("professionalData.roleID", {message: "Role is requierd"});
        } else {
            form.clearErrors("professionalData.roleID");
        }

        if (values.professionalData.employmentTypeID === "no-value") {
            return form.setError("professionalData.employmentTypeID", {message: "Employment type is required"});
        } else {
            form.clearErrors("professionalData.employmentTypeID");
        }

        // check if working time account status end date is not correctly filled: shows an error message.
        if (values.workingTimeAccount.workingTimeAccountStatusStartMonthDate?.year || values.workingTimeAccount.workingTimeAccountStatusStartMonthDate?.month) {
            if (!values.workingTimeAccount.workingTimeAccountStatusStartMonthDate.year || !values.workingTimeAccount.workingTimeAccountStatusStartMonthDate.month) {
                if (!values.workingTimeAccount.workingTimeAccountStatusStartMonthDate.year) {
                    form.setError('workingTimeAccount.workingTimeAccountStatusStartMonthDate.year', {message: 'Field is required.'})
                }
                if (!values.workingTimeAccount.workingTimeAccountStatusStartMonthDate.month) {
                    form.setError('workingTimeAccount.workingTimeAccountStatusStartMonthDate.month', {message: 'Field is required.'})
                }
                redirect = true;
            }
        }
        if (values.workingTimeAccount.workingTimeAccountStatusEndMonthDate?.year || values.workingTimeAccount.workingTimeAccountStatusEndMonthDate?.month) {
            if (!values.workingTimeAccount.workingTimeAccountStatusEndMonthDate.year || !values.workingTimeAccount.workingTimeAccountStatusEndMonthDate.month) {
                if (!values.workingTimeAccount.workingTimeAccountStatusEndMonthDate.year) {
                    form.setError('workingTimeAccount.workingTimeAccountStatusEndMonthDate.year', {message: 'Field is required.'})
                }
                if (!values.workingTimeAccount.workingTimeAccountStatusEndMonthDate.month) {
                    form.setError('workingTimeAccount.workingTimeAccountStatusEndMonthDate.month', {message: 'Field is required.'})
                }
                redirect = true;
            }
        }
        // check if balance boundaries end date is not correctly filled: shows an error message.
        if (values.workingTimeAccount.hasBalanceBoundary) {
            if (!values.workingTimeAccount.balanceBoundaryLowerLimit.hours && !values.workingTimeAccount.balanceBoundaryLowerLimit.minutes) {
                form.setError('workingTimeAccount.balanceBoundaryLowerLimit.hours', {message: 'Field is required.'})
                redirect = true;
            }
            if (!values.workingTimeAccount.balanceBoundaryUpperLimit.hours && !values.workingTimeAccount.balanceBoundaryUpperLimit.minutes) {
                form.setError('workingTimeAccount.balanceBoundaryUpperLimit.hours', {message: 'Field is required.'})
                redirect = true;
            }
            if (values.workingTimeAccount.balanceBoundaryStartMonthDate.year || values.workingTimeAccount.balanceBoundaryStartMonthDate.month) {
                if (!values.workingTimeAccount.balanceBoundaryStartMonthDate.year || !values.workingTimeAccount.balanceBoundaryStartMonthDate.month) {
                    if (!values.workingTimeAccount.balanceBoundaryStartMonthDate.year) {
                        form.setError('workingTimeAccount.balanceBoundaryStartMonthDate.year', {message: 'Field is required.'})
                    }
                    if (!values.workingTimeAccount.balanceBoundaryStartMonthDate.month) {
                        form.setError('workingTimeAccount.balanceBoundaryStartMonthDate.month', {message: 'Field is required.'})
                    }
                    redirect = true;
                }
            }
            if (values.workingTimeAccount.balanceBoundaryEndMonthDate.year || values.workingTimeAccount.balanceBoundaryEndMonthDate.month) {
                if (!values.workingTimeAccount.balanceBoundaryEndMonthDate.year || !values.workingTimeAccount.balanceBoundaryEndMonthDate.month) {
                    if (!values.workingTimeAccount.balanceBoundaryEndMonthDate.year) {
                        form.setError('workingTimeAccount.balanceBoundaryEndMonthDate.year', {message: 'Field is required.'})
                    }
                    if (!values.workingTimeAccount.balanceBoundaryEndMonthDate.month) {
                        form.setError('workingTimeAccount.balanceBoundaryEndMonthDate.month', {message: 'Field is required.'})
                    }
                    redirect = true;
                }
            }
        }

        if (redirect) {
            stepper.goToStep(WORKING_TIME_ACCOUNT);
            return;
        }

        setApiErrors({});
        form.clearErrors();

        axiosInstance.post(employeesUrl + 'validate-only/', data)
            .then((res) => {
                stepper.goToNextStep();
                setStartingDate(values.professionalData.entranceDate);
            })
            .catch((err) => {
                let responseErrors = err.response.data

                if (responseErrors["non_field_errors"] || responseErrors["detail"]) {
                    setApiErrors(err.response.data)
                    return;
                }

                let responseErrorsKeys = Object.keys(responseErrors)
                let employeeData: FieldErrors = {};
                let professionalData: FieldErrors = {};
                let vacationData: FieldErrors = {};
                let workingTimeAccount: FieldErrors = {};
                let workingPlanData: FieldErrors = {};
                let accountAndPassword: FieldErrors = {};

                responseErrorsKeys.forEach(key => {
                    if (EMPLOYEE_DATA_STEP_ERRORS.includes(key)) {
                        employeeData = {
                            ...employeeData,
                            [key]: responseErrors[key]
                        }
                    } else if (PROFESSIONAL_DATA_STEP_ERRORS.includes(key)) {
                        professionalData = {
                            ...professionalData,
                            [key]: responseErrors[key]
                        }
                    } else if (VACATION_DATA_STEP_ERRORS.includes(key)) {
                        vacationData = {
                            ...vacationData,
                            [key]: responseErrors[key]
                        }
                    } else if (WORKING_TIME_ACCOUNT_STEP_ERRORS.includes(key)) {
                        workingTimeAccount = {
                            ...workingTimeAccount,
                            [key]: responseErrors[key]
                        }
                    } else if (WORKING_PLAN_STEP_ERRORS.includes(key)) {
                        workingPlanData = {
                            ...workingPlanData,
                            [key]: responseErrors[key]
                        }
                    } else if (ACCOUNT_AND_PASSWORD_STEP_ERRORS.includes(key)) {
                        accountAndPassword = {
                            ...accountAndPassword,
                            [key]: responseErrors[key]
                        }
                    }
                })

                setApiErrors(prevState => ({
                    employeeData,
                    professionalData,
                    vacationData,
                    workingTimeAccount,
                    workingPlanData,
                    accountAndPassword
                }))
            })
    }

    const onSubmit = () => {
        let values= form.getValues();
        let data: z.infer<typeof createEmployeeFormSchemaBackend> = transformDataFromFrontendToBackendShape(values, true)
        
        // Mimoun

        console.log("abc values: ", values);

        console.log("abc values transformed: ", data);


        axiosInstance.post(employeesUrl, data)
            .then((res) => {
                setApiErrors({})
                closeModal.current?.click();
                sideFunctions?.forEach((func) => func());
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data);
            })
    };

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "vacationData.hasCarryover") {
                setHasCarryover(value.vacationData?.hasCarryover)
            } else if (name === "vacationData.hasDifferentExpiryDate") {
                setHasDifferentExpiryDate(value.vacationData?.hasDifferentExpiryDate)
            } else if (name === "workingTimeAccount.hasWorkingTimeAccount") {
                setHasWorkingTimeAccount(() => {
                    form.setValue("workingTimeAccount.hasBalanceBoundary", false)
                    form.setValue("workingTimeAccount.workingTimeAccountStatusStartMonthDate", {})
                    form.setValue("workingTimeAccount.workingTimeAccountStatusEndMonthDate", {})
                    return value.workingTimeAccount?.hasWorkingTimeAccount
                })
            } else if (name === "workingTimeAccount.hasBalanceBoundary") {
                form.setValue("workingTimeAccount.balanceBoundaryLowerLimit", {})
                form.setValue("workingTimeAccount.balanceBoundaryUpperLimit", {})
                form.setValue("workingTimeAccount.balanceBoundaryStartMonthDate", {})
                form.setValue("workingTimeAccount.balanceBoundaryEndMonthDate", {})
                setHasBalanceBoundary(value.workingTimeAccount?.hasBalanceBoundary)
            }
        })
        return () => subscription.unsubscribe()
    }, []);

    useEffect(() => {
        stepper.steps.forEach(step => stepper.attachError(step.name, false))

        let objKeys = Object.keys(errors.fieldErrors);


        objKeys = objKeys.reduce((acc: string[], key) => {
            if (typeof errors.fieldErrors[key] === 'object' && !Array.isArray(errors.fieldErrors[key])) {
                const nestedKeys = Object.keys(errors.fieldErrors[key]);
                nestedKeys.forEach(nestedKey => {
                    acc.push(`${key}.${nestedKey}`);
                });
            } else {
                acc.push(key);
            }
            return acc;
        }, []);

        if (objKeys.length) {

            for (let key of objKeys) {
                if (EMPLOYEE_DATA_STEP_ERRORS.map(e => 'employeeData.'+e).includes(key)) {
                    stepper.attachError(EMPLOYEE_DATA, true);
                    stepper.goToStep(EMPLOYEE_DATA);
                    break;
                } else if (PROFESSIONAL_DATA_STEP_ERRORS.map(e => 'professionalData.'+e).includes(key)) {
                    stepper.attachError(PROFESSIONAL_DATA, true)
                    stepper.goToStep(PROFESSIONAL_DATA);
                    break;
                } else if (VACATION_DATA_STEP_ERRORS.map(e => 'vacationData.'+e).includes(key)) {
                    stepper.attachError(VACATION_DATA, true)
                    stepper.goToStep(VACATION_DATA);
                    break;
                } else if (WORKING_TIME_ACCOUNT_STEP_ERRORS.map(e => 'workingTimeAccount.'+e).includes(key)) {
                    stepper.attachError(WORKING_TIME_ACCOUNT, true)
                    stepper.goToStep(WORKING_TIME_ACCOUNT);
                    break;
                } else if (ACCOUNT_AND_PASSWORD_STEP_ERRORS.map(e => 'accountAndPassword.'+e).includes(key)) {
                    stepper.attachError(ACCOUNT_AND_PASSWORD, true)
                    stepper.goToStep(ACCOUNT_AND_PASSWORD);
                    break;
                }
            }
        }
    }, [errors.fieldErrors]);

    useEffect(() => {
        if (stepper.currentStep !== WORKING_PLAN && stepper.steps.find(s => s.name === WORKING_PLAN)?.isOpen) {
            stepper.closeSteps(ACCOUNT_AND_PASSWORD)
        }
        if (stepper.currentStep === WORKING_PLAN){
            setDialogContentClassName("min-w-[900px]")
        }
        else
            setDialogContentClassName("min-w-[600px]")
    }, [stepper.currentStep]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <Tabs
                    value={stepper.currentStep}
                    onValueChange={(value) => {
                        stepper.setCurrentStep(value)
                    }}
                >
                    <TabsList className="mb-2">
                        {stepper.steps.map((step, index, array) => (
                            <TabsTrigger className="cursor-default" value={step.name} disabled={!step.isOpen}>{`Step ${index + 1}`}</TabsTrigger>
                        ))}
                    </TabsList>
                    {
                        stepper.refineTo().map(
                            (step, index, array) => {
                                    return <TabsContent value={step.name}>

                                        <div className={"flex flex-col gap-4 p-1 max-h-[50vh] overflow-y-scroll"}>
                                            {step && step.name ? (
                                                step.name === WORKING_TIME_ACCOUNT ? (
                                                    <WorkingTimeAccountDataForm
                                                        form={form}
                                                        errors={errors}
                                                        hasWorkingTimeAccount={hasWorkingTimeAccount}
                                                        hasBalanceBoundary={hasBalanceBoundary}
                                                    />
                                                ) :
                                                step.name === VACATION_DATA ? (
                                                    <VacationDataForm
                                                        form={form}
                                                        hasDifferentExpiryDate={hasDifferentExpiryDate}
                                                        hasCarryover={hasCarryover}
                                                    />
                                                ) :
                                                step.name === EMPLOYEE_DATA ? (
                                                    <EmployeeDataForm form={form}/>
                                                ) :
                                                step.name === PROFESSIONAL_DATA ? (
                                                    <ProfessionalDataForm form={form}/>
                                                ) :
                                                step.name === ACCOUNT_AND_PASSWORD ? (
                                                    <AccountAndPasswordDataForm form={form}/>
                                                ) :
                                                step.name === WORKING_PLAN ? (
                                                    
                                                    <WorkingPlanDataForm form={form} setStartingDate={setStartingDate} startingDate={startingDate} workingPlan={workingPlan} setWorkingPlan={setWorkingPlan}/>
                                                ) : (
                                                    step.content ? <>No content</> : <>No content available</>
                                                )
                                            ) : <>Step not defined</>}

                                            <ErrorMessageList errors={errors.nonFieldErrors}/>
                                            <ErrorMessageList errors={errors.detailErrors}/>
                                        </div>
                                    </TabsContent>
                                }
                            )
                        }
                </Tabs>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    {stepper.currentStep !== WORKING_PLAN ? (
                        <>
                            {stepper.currentStep !== EMPLOYEE_DATA ? (
                                <Button
                                    variant="outline"
                                    type={"button"}
                                    onClick={() => stepper.goToPreviousStep()}
                                ><FormattedMessage id={"button.back"} defaultMessage={"Back"}/></Button>
                            ) : (
                                <DialogClose asChild>
                                    <Button variant="outline">
                                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                    </Button>
                                </DialogClose>
                            )}
                            <Button
                                variant="taimDefault"
                                type="button"
                                onClick={() => {
                                    if (stepper.currentStep === ACCOUNT_AND_PASSWORD) {
                                        handleValidate()
                                    } else {
                                        stepper.goToNextStep()
                                    }
                                }}
                            >
                                {stepper.currentStep === ACCOUNT_AND_PASSWORD ? (
                                    <FormattedMessage id={"button.validate"} defaultMessage={"Validate"}/>
                                ) : (
                                    <FormattedMessage id={"button.next"} defaultMessage={"Next"}/>
                                )}
                            </Button>
                        </>
                    ) : (
                        <>
                            <DialogClose ref={closeModal as any} asChild>
                                <Button className="hidden">close</Button>
                            </DialogClose>
                            <Button
                                variant="outline"
                                type={"button"}
                                onClick={() => {
                                    stepper.goToPreviousStep(true)
                                }}
                            ><FormattedMessage id={"button.back"} defaultMessage={"Back"}/></Button>
                            <Button
                                variant="taimDefault"
                                type="submit"
                                onClick={onSubmit}
                            ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                        </>
                    )}

                </DialogFooter>
            </form>
        </Form>
    )
}
